import { memo } from "react"
import { ZoomableGroup, ComposableMap, Geographies, Geography } from "react-simple-maps"
import { theme } from "theme"
import { FILING_COUNTRIES, EU_COUNTRIES } from "helpers/constants"

const MapChart = ({ setTooltipContent, setCurrentCountry, filings, filingsInclude, height }) => {  
  const onMouseEnter = (geo) => {
    setTooltipContent({
      country: geo.properties.name,
    })
  }

  const onClick = (geo) => {
    // Set current country only when it is in FILING_COUNTRIES
    if (FILING_COUNTRIES.includes(geo.properties.name)) {
      if (EU_COUNTRIES.includes(geo.properties.name)) {
        setCurrentCountry("European Union")
      } else {
        setCurrentCountry(geo.properties.name)
      }
    }
  }

  const onMouseLeave = () => {
    setTooltipContent()
  }

  const getCountryColor = (countryName) => {
    let filing = filings.filter(filingsInclude).find(c => c.country === countryName)
    
    // If countryName is in the European Union
    // Find filing record with country "European Union" and return its color according to status
    if (EU_COUNTRIES.includes(countryName)) {
      filing = filings.filter(filingsInclude).find(c => c.country === "European Union")
    }

    if (filing) {
      if (filing.status === "Not Started") {
        return theme.palette.error.main
      } else if (filing.status === "Prepared" || filing.status === "Submitted") {
        return theme.palette.warning.main
      } else if (filing.status === "Paid" || filing.status === "Confirmed") {
        return theme.palette.success.main
      }
    } else {
      return "#D3D3D3"
    }
  }

  return (
    <div data-tip="">
      <ComposableMap height={height} style={{borderColor:"black", borderWidth: 1}}>
        <ZoomableGroup center={[14, 8]}>
          <Geographies geography="/features.json">
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onClick={() => onClick(geo)}
                  onMouseEnter={() => onMouseEnter(geo)}
                  onMouseLeave={() => onMouseLeave()}
                  style={{
                    default: {
                      fill: getCountryColor(geo.properties.name),
                      stroke: "#F6F6FA",
                      outline: "none"
                    },
                    hover: {
                      fill: "#F53",
                      outline: "none"
                    },
                    pressed: {
                      fill: "#E42",
                      outline: "none"
                    }
                  }}
                />
              ))
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
    </div>
  )
}

export default memo(MapChart)
