import { useState, useEffect, useContext, Fragment } from "react"
import ReactTooltip from "react-tooltip"
import { 
  Grid, Paper, Select, InputLabel, FormControl, MenuItem, Button,
  Card, CardHeader, CardContent, Divider, Alert
} from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import FilingsMapChart from "components/filings/charts/FilingsMapChart"
import FilingsPieChart from "components/filings/charts/FilingsPieChart"
// import COUNTRIES from "helpers/countries"
import { YEARS, QUARTERS, FILING_STATUSES, FILING_TYPES, FILING_COUNTRIES as COUNTRIES } from "helpers/constants"
import { AuthContext } from "helpers/auth"
import FilingsModal from "components/filings/FilingsModal"
import DeleteDialog from "components/common/DeleteDialog"
import FilingsFileModal from "components/filings/FilingsFileModal"

const FilingsPage = () => {
  const [content, setContent] = useState({})
  
  const [filings, setFilings] = useState([])

  const [filters, setFilters] = useState({
    country: "",
    filingType: FILING_TYPES[0],
    year: (new Date().getFullYear()).toString(), // Get current year
    quarter: `Q${Math.floor((new Date().getMonth() + 3) / 3).toString()}`, // Get current quarter
    status: "",
  })

  const auth = useContext(AuthContext)

  const [updated, setUpdated] = useState(0)
  const toggleUpdate = () => setUpdated(!updated)

  const deleteFiling = async (filing) => {
    await fetch(`${process.env.REACT_APP_API_PROXY}/api/filings/${encodeURIComponent(filing._id)}`, { 
      method: "DELETE", 
      credentials: "include",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + auth.user.token
      }
    })
    // deleteFiling((filings) => filings.filter((f) => f._id !== filing._id))
    toggleUpdate()
  }

  const filingColumns = [
    { 
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <Fragment>
          <FilingsFileModal filing={params.row} actions={{ toggleUpdate }} />
          <FilingsModal filing={params.row} actions={{ toggleUpdate }} />
          <DeleteDialog objectName={"filing"} deleteFunction={() => deleteFiling(params.row)} />
        </Fragment>
      )
    },
    { field: "country", headerName: "Country", minWidth: 200, flex: 1, },
    { field: "filingType", headerName: "Filing Type", minWidth: 200, flex: 1, },
    { field: "year", headerName: "Year", minWidth: 200, flex: 1, },
    { field: "quarter", headerName: "Quarter", minWidth: 200, flex: 1, },
    { field: "status", headerName: "Status", minWidth: 200, flex: 1, },
  ]

  const filingsInclude = (filing) => {
    if (filters.country && filing.country !== filters.country) {
      return false
    }
    if (filters.filingType && filing.filingType !== filters.filingType) {
      return false
    }
    if (filters.year && filing.year !== filters.year) {
      return false
    }
    if (filters.quarter && filing.quarter !== filters.quarter) {
      return false
    }
    if (filters.status && filing.status !== filters.status) {
      return false
    }
    return true
  }

  useEffect(() => {
    if (auth.user) {
      const fetchFilingsData = async () => {
        const filingsResponse = await fetch(`${process.env.REACT_APP_API_PROXY}/api/filings`, { 
          method: "GET", 
          credentials: "include",
          headers: { 
            "Content-Type": "application/json",
            "Authorization": "Bearer " + auth.user.token
          }
        })
        const response = await filingsResponse.json()
        setFilings(response)
      }
  
      fetchFilingsData()
    }
  }, [auth, updated])

  return (
    <Grid container spacing={3}>
      {/* World Map */}
      <Grid item xs={8}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", height: "100%" }}>
          <FilingsMapChart 
            setTooltipContent={setContent} 
            setCurrentCountry={(value) => setFilters({...filters, country: value})} 
            filings={filings}
            filingsInclude={filingsInclude}
            height={450} 
          />
          <ReactTooltip className="tooltip">
            { content && 
              <div>
                <div style={{ "paddingBottom": 5 }}>{content.country}</div>
              </div>
            }
          </ReactTooltip>
        </Paper>
      </Grid>

      {/* Task Status Pie Chart */}
      <Grid item xs={4}>
        <Card sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <CardHeader title="Filing status" />
          <Divider />
          <CardContent sx={{ px: 4 }}>
            <FilingsPieChart 
              filters={filters}
              setFilters={setFilters}
              filingsInclude={filingsInclude}
              filings={filings}
            />
          </CardContent>
        </Card>
      </Grid>

      {/* Legend */}
      <Grid item xs={6}>
        <Alert severity="info" sx={{ alignItems: "center", display: "flex" }}>
          <div style={{ "fontWeight": "bold" }}>Filing Status Legend:</div>
          <ul>
            <li><u>Not Started</u>: No VAT filing has been started.</li>
            <li><u>Prepared</u>: VAT filing documentation in progress.</li>
            <li><u>Submitted</u>: VAT filing submitted to relevant authorities.</li>
            <li><u>Paid</u>: Tax payment made to authorities.</li>
            <li><u>Confirmed</u>: Received confirmation from authorities that everything is in order.</li>
          </ul>
        </Alert>
      </Grid>

      {/* Filters */}
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", height: "100%" }}>
          <Grid container spacing={2}>
            {/* Country filter */}
            <Grid item xs={2}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel shrink>Country</InputLabel>
                <Select displayEmpty notched label="Country" value={filters.country} onChange={(e) => {setFilters({...filters, country: e.target.value})}}>
                  <MenuItem key={"All Countries"} value="">All Countries</MenuItem>
                  { COUNTRIES.map(country => {
                    return (
                      <MenuItem key={country} value={country}>{country}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>

            {/* Filing Type filter */}
            <Grid item xs={2}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel shrink>Filing Type</InputLabel>
                <Select displayEmpty notched label="Filing Type" value={filters.filingType} onChange={(e) => {setFilters({...filters, filingType: e.target.value})}}>
                  <MenuItem key={"All Filing Types"} value="">All Filing Types</MenuItem>
                  { FILING_TYPES.map(filingType => {
                    return (
                      <MenuItem key={filingType} value={filingType}>{filingType}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>

            {/* Year filter */}
            <Grid item xs={1}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel shrink>Year</InputLabel>
                <Select displayEmpty notched label="Year" value={filters.year} onChange={(e) => {setFilters({...filters, year: e.target.value})}}>
                  <MenuItem key={"All Years"} value={""}>All Years</MenuItem>
                  { YEARS.map(year => {
                    return (
                      <MenuItem key={year} value={year}>{year}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>

            {/* Quarter filter */}
            <Grid item xs={2}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel shrink>Quarter</InputLabel>
                <Select displayEmpty notched label="Quarter" value={filters.quarter} onChange={(e) => {setFilters({...filters, quarter: e.target.value})}}>
                  <MenuItem key={"All Quarters"} value="">All Quarters</MenuItem>
                  { QUARTERS.map(quarter => {
                    return (
                      <MenuItem key={quarter} value={quarter}>{quarter}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>

            {/* Status filter */}
            <Grid item xs={2}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel shrink>Status</InputLabel>
                <Select displayEmpty notched label="Status" value={filters.status} onChange={(e) => {setFilters({...filters, status: e.target.value})}}>
                  <MenuItem key={"All Statuses"} value="">All Statuses</MenuItem>
                  { FILING_STATUSES.map(status => {
                    return (
                      <MenuItem key={status} value={status}>{status}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>

            {/* Clear all filters */}
            <Grid item xs={2}>
              <Button 
                variant="outlined" 
                sx={{ height: "100%", width: "100%" }}
                onClick={() => {
                  setFilters({
                    country: "",
                    filingType: "",
                    year: "",
                    quarter: "",
                    status: ""
                  })
                }}
              >
                Clear filters
              </Button>
            </Grid>

            {/* Add filing */}
            <Grid item xs={1}>
              <FilingsModal actions={{ toggleUpdate }}/>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      {/* Filings */}
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", height: 800 }}>
          <DataGrid
            rows={filings.filter(filingsInclude)}
            columns={filingColumns}
            getRowId={(row) => row._id}
            disableSelectionOnClick
          />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default FilingsPage
