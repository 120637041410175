import { Chip } from "@mui/material"

const StatusCell = ({ status }) => {
  const color = (status === "Pending" && "warning")
    || (status === "Done" && "success")
    || (status === "Tasks Overdue" && "error")
    || "primary"

  return (
    <Chip sx={{ minWidth: "130px" }} label={status} color={color} />
  )
}
 
export default StatusCell
