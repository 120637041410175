import "./App.css"
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"
import { AuthProvider, RequireAuth } from "helpers/auth"
import { UsersProvider } from "helpers/users"
import { SnackbarProvider } from "helpers/snackbar"
import AppLayout from "components/layout/AppLayout"
import TaskPage from "components/tasks/TaskPage"
import TaskDetail from "components/tasks/TaskDetail"
import ObligationPage from "components/obligations/ObligationPage"
import ProjectPage from "components/projects/ProjectPage"
import ProjectDetail from "components/projects/ProjectDetail"
import LoginPage from "components/layout/LoginPage"
import EntityPage from "components/entities/EntityPage"
import ResidencePage from "components/residences/ResidencePage"
import PropertyPage from "components/properties/PropertyPage"
import ReturnsPage from "components/returns/ReturnsPage"
import FilingsPage from "components/filings/FilingsPage"
import CustomerPage from "components/customers/CustomerPage"
import FilePage from "components/files/FilePage"
import SettingsPage from "components/settings/SettingsPage"


const App = () => {
  return (
    <AuthProvider>
      <UsersProvider>
        <SnackbarProvider>
          <Router>
            <Routes>
              <Route>
                <Route path="/login" element={<LoginPage />} />
              </Route>
              <Route element={<AppLayout />}>
                <Route path="*" element={<Navigate to="/filings" replace />} />
                <Route path="/tasks">
                  <Route path="" element={<RequireAuth><TaskPage /></RequireAuth>} />
                  <Route path=":id" element={<RequireAuth><TaskDetail /></RequireAuth>} />
                </Route>
                <Route path="/obligations" element={<RequireAuth><ObligationPage /></RequireAuth>} />
                <Route path="/projects">
                  <Route path="" element={<RequireAuth><ProjectPage /></RequireAuth>} />
                  <Route path=":id" element={<RequireAuth><ProjectDetail /></RequireAuth>} />
                </Route>
                <Route path="/entities" element={<RequireAuth><EntityPage /></RequireAuth>} />
                <Route path="/residences" element={<RequireAuth><ResidencePage /></RequireAuth>} />
                <Route path="/properties" element={<RequireAuth><PropertyPage /></RequireAuth>} />
                <Route path="/returns" element={<RequireAuth><ReturnsPage /></RequireAuth>} />
                <Route path="/filings" element={<RequireAuth><FilingsPage /></RequireAuth>} />
                <Route path="/customers" element={<RequireAuth><CustomerPage /></RequireAuth>} />
                <Route path="/files" element={<RequireAuth><FilePage /></RequireAuth>} />
                <Route path="/settings" element={<RequireAuth><SettingsPage /></RequireAuth>} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Route>
            </Routes> 
          </Router>
        </SnackbarProvider>
      </UsersProvider>
    </AuthProvider>
  )
}

export default App
