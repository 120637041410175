import "chart.js/auto"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { Fragment, useEffect, useState } from "react"
import { Doughnut } from "react-chartjs-2"
import { theme } from "theme"
import { FILING_STATUSES } from "helpers/constants"

const TaskPieChart = ({ filters, setFilters, filingsInclude, filings }) => {
  const [data, setData] = useState([])
  const datasetLabels = FILING_STATUSES

  const pieOptions = {
    plugins: {
      legend: {
        display: false,
        showTooltips: false
      },
      tooltip: {
        enabled: false
      },
      title: {
        display: false
      },
      datalabels: {
        formatter: (value, context) => {
          return value ? datasetLabels[context.dataIndex] + "\n" + value : ""
        },
        color: "white",
        textAlign: "center"
      }
    }, 
    onClick: (event, element) => {
      if (element.length > 0) {
        // setCurrentStatus(datasetLabels[element[0].index])
        setFilters({
          ...filters,
          status: datasetLabels[element[0].index]
        })
      }
    }
  }

  const taskStatusData = {
    labels: datasetLabels,
    datasets: [
      { 
        label: "Filing Status", 
        data: data,
        backgroundColor: [
          theme.palette.error.main,     // Not Started - Red
          theme.palette.warning.main,   // Prepared - Yello
          theme.palette.warning.main,   // Submitted - Yello
          theme.palette.success.main,   // Paid - Green
          theme.palette.success.main,   // Confirmed - Green
        ]
      }
    ]
  }

  useEffect(() => {
    const taskStatusCount = datasetLabels.map(status => {
      return filings.filter(filingsInclude).filter(filing => filing.status === status).length
    })
    setData(taskStatusCount)
  }, [filings, filingsInclude, datasetLabels])

  return (
    <Fragment>
      <Doughnut 
        data={taskStatusData} 
        plugins={[ChartDataLabels]} 
        options={pieOptions}
      />
    </Fragment>
  )
}

export default TaskPieChart