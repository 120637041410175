import { Fragment, useState, useContext } from "react"
import moment from "moment"
import { Typography, Card, CardContent, Link as MUILink } from "@mui/material"
import { AuthContext } from "helpers/auth"
import { UsersContext } from "helpers/users"
import DeleteDialog from "components/common/DeleteDialog"
import ScrollableMention from "components/tasks/messages/ScrollableMention"

const TaskMessageCard = ({ task, message, actions }) => {
  const [messageContent, setMessageContent] = useState(message.content)
  const [readOnly, setReadOnly] = useState(true)
  const auth = useContext(AuthContext)
  const users = useContext(UsersContext)
  
  const enableEdit = () => { setReadOnly(false) }

  const updateMessage = async () => {
    try {
      await fetch(`${process.env.REACT_APP_API_PROXY}/api/tasks/${encodeURIComponent(task._id)}/messages/${encodeURIComponent(message._id)}`, { 
        method: "PUT", 
        credentials: "include",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": "Bearer " + auth.user.token
        },
        body: JSON.stringify({
          content: messageContent
        })
      })
      setReadOnly(true)
    } catch (error) {
      console.log(error)
    }
  }

  const deleteMessage = async (message) => {
    try {
      await fetch(`${process.env.REACT_APP_API_PROXY}/api/tasks/${encodeURIComponent(task._id)}/messages/${encodeURIComponent(message._id)}`, { 
        method: "DELETE", 
        credentials: "include",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": "Bearer " + auth.user.token
        }
      })
      actions.toggleUpdate()
    } catch (error) {
      console.log(error)
    }
  }  

  const renderMessageContent = (message) => {
    const map = {'&': '&amp;', '<': '&lt;', '>': '&gt;', '"': '&quot;', "'": '&#x27;', "/": '&#x2F;',}
    const reg = /[&<>"'/]/ig
    let newMessage = message.replace(reg, (match) => (map[match]))
    newMessage = newMessage.split("@(").join("<a href='#' id='")
    newMessage = newMessage.split(")[").join("'>")
    newMessage = newMessage.split("]").join("</a>")
    return newMessage.trim()
  }

  return (
    <Card elevation={12} style={{ overflow: "visible" }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {/* Message details */}
          {moment(message.createdAt).format("LLL")}, by {users.findNameFromId(message.createdBy)}

          {/* Message actions only available to user who created message */}
          {auth.user._id === message.createdBy ? (
            <Fragment>
              {readOnly ? <MUILink href="#" sx={{ pl: 1 }} onClick={enableEdit}>Edit</MUILink>
                        : <MUILink href="#" sx={{ pl: 1 }} onClick={updateMessage}>Submit</MUILink>}
              <DeleteDialog objectName={"message"} deleteFunction={() => deleteMessage(message)} linkOnly />
            </Fragment>
          ): null}
        </Typography>

        {/* Message content */}
        { readOnly 
          ? <div dangerouslySetInnerHTML={{ __html: renderMessageContent(messageContent) }}></div> 
          : <ScrollableMention data={users.usersList} value={messageContent} setValue={setMessageContent} />
        }
      </CardContent>
    </Card>
  )
}

export default TaskMessageCard
