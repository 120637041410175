import { Fragment, useState, useContext } from "react"
import moment from "moment"
import { Button, Modal, Box, Divider, TextField, Grid, MenuItem, IconButton } from "@mui/material"
import { Edit as EditIcon } from "@mui/icons-material"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { ValidatorForm, SelectValidator } from "react-material-ui-form-validator"
import UserSelect from "components/common/UserSelect"
import { AuthContext } from "helpers/auth"
import { UsersContext } from "helpers/users"
import { SnackbarContext } from "helpers/snackbar"

const STATUSES = ["Pending", "Done", "Tasks Overdue"]

const ProjectModal = ({ task, actions }) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    if (task) { 
      // Editing task in task page
      actions.toggleUpdate() 
    } else {
      setName("")
      setDescription("")
      setDeadlineDate(moment(new Date()).format("YYYY-MM-DD"))
      setEntity("")
      setStatus("")
      setAssignedUsers([])
    }
  }

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  }

  const [name, setName] = useState(task ? task.name : "")
  const [description, setDescription] = useState(task ? task.description : "")
  const [deadlineDate, setDeadlineDate] = useState(task ? task.deadlineDate : moment(new Date()).format("YYYY-MM-DD"))
  const [entity, setEntity] = useState(task ? task.entity?._id : "")
  const [status, setStatus] = useState(task ? task.status : "")
  const [assignedUsers, setAssignedUsers] = useState(task ? task.assignedUsers.map((u) => (u._id)) : [])
  const auth = useContext(AuthContext)
  const users = useContext(UsersContext)
  const snackbar = useContext(SnackbarContext)

  const handleSubmit = async () => {
    try {
      const url = task ? `${process.env.REACT_APP_API_PROXY}/api/tasks/${encodeURIComponent(task._id)}` : `${process.env.REACT_APP_API_PROXY}/api/tasks`
      const method = task ? "PUT" : "POST"
      await fetch(url, { 
        method: method, 
        credentials: "include",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": "Bearer " + auth.user.token
        },
        body: JSON.stringify({
          name,
          description,
          deadlineDate,
          entity,
          status,
          assignedUsers
        }) 
      })
      actions.toggleUpdate()
      snackbar.setMessage({ message: `Project successfully ${task ? "updated" : "created"}!`, severity: "success" })
      handleClose()
    } catch (error) {
      console.log(error)
      snackbar.setMessage({ message: error.message, severity: "error" })
    }
  }

  return (
    <Fragment>
      {task ? 
        <IconButton variant="contained" onClick={handleOpen}>
          <EditIcon />
        </IconButton> 
      : 
        <Button color="primary" variant="contained" onClick={handleOpen}>
          Add Project
        </Button>
      }
      <Modal open={open} onClose={handleClose}>
        <Box sx={boxStyle}>
          <ValidatorForm onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Header */}
              <Grid item xs={12}>
                {task?.name || "New project"}
                <Divider sx={{ p: 1 }} />  
              </Grid>

              {/* Task name */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Task Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)} 
                />
              </Grid>

              {/* Description */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)} 
                />
              </Grid>

              {/* Deadline date */}
              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Deadline Date"
                    value={deadlineDate}
                    onChange={(deadlineDate) => {setDeadlineDate(deadlineDate)}}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </LocalizationProvider>
              </Grid>

              {/* Legal Entity */}
              <Grid item xs={3}>
                <SelectValidator
                  fullWidth
                  select
                  variant="outlined"
                  label="Legal Entity"
                  value={entity}
                  onChange={(e) => setEntity(e.target.value)}
                  validators={["required"]}
                  errorMessages={["This field is required"]}
                >
                  {users.entityList?.entities?.map(ent => (
                    <MenuItem key={ent._id} value={ent._id}>{ent.name}</MenuItem>
                  ))}
                </SelectValidator>
              </Grid>

              {/* Status */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  select
                  variant="outlined"
                  label="Status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)} 
                >
                  {STATUSES.map(status => (
                    <MenuItem key={status} value={status}>{status}</MenuItem>
                  ))}
                </TextField>
              </Grid>

              {/* Assigned users */}
              <Grid item xs={6}>
                <UserSelect assignedUsers={assignedUsers} setAssignedUsers={setAssignedUsers} />
              </Grid>

              {/* Submit */}
              <Grid item xs={12}>
                <Button variant="contained" type="submit">Submit</Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        </Box>
      </Modal>
    </Fragment>
  )
}

export default ProjectModal
