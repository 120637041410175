import { useState, useEffect, useContext } from "react"
import { 
  TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
  Grid, Paper, Typography, Box
} from "@mui/material"
import EntityModal from "components/entities/EntityModal"
import DeleteDialog from "components/common/DeleteDialog"
import { AuthContext } from "helpers/auth"
import { UsersContext } from "helpers/users"

const EntityPage = () => {
  const auth = useContext(AuthContext)
  const users = useContext(UsersContext)
  const [updated, setUpdated] = useState(0)
  const toggleUpdate = () => setUpdated(!updated)

  const deleteEntity = async (entity) => {
    await fetch(`${process.env.REACT_APP_API_PROXY}/api/entities/${encodeURIComponent(entity._id)}`, { 
      method: "DELETE", 
      credentials: "include",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + auth.user.token
      }
    })
    toggleUpdate()
  }

  useEffect(() => { 
    if (auth.user) { users.fetchEntityList(auth.user.token) }
  }, [updated]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box sx={{ alignItems: "center", display: "flex", justifyContent: "space-between", flexWrap: "wrap", m: -1 }}>
          <Typography sx={{ m: 1 }} variant="h4">Legal Entities</Typography>
          <Box sx={{ m: 1 }}>
            <EntityModal actions={{ toggleUpdate }} />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper} sx={{ height: "100%" }} >
          <Table sx={{border: "1px grey"}}>
            <colgroup>
              <col style={{width:"60%"}} />
              <col style={{width:"20%"}} />
              <col style={{width:"20%"}} />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Country</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.entityList?.entities?.map(ent => (
                <TableRow key={ent._id}>
                  <TableCell>{ent.name}</TableCell>
                  <TableCell>{ent.country}</TableCell>
                  <TableCell align="center">
                    <EntityModal entity={ent} actions={{ toggleUpdate }} />
                    <DeleteDialog objectName={"entity"} deleteFunction={() => deleteEntity(ent)} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default EntityPage
