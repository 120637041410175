import { useEffect, useState, useContext } from "react"
import moment from "moment"
import { Grid, Paper, Typography, Box,TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material"
import { AuthContext } from "helpers/auth"
import PropertyMap from "components/properties/PropertyMap"
import PropertyModal from "components/properties/PropertyModal"
import DeleteDialog from "components/common/DeleteDialog"

const PropertyPage = () => {
  const [properties, setProperties] = useState([])
  const [updated, setUpdated] = useState(0)
  const auth = useContext(AuthContext)
  const toggleUpdate = () => setUpdated(!updated)

  const deleteProperty = async (property) => {
    await fetch(`${process.env.REACT_APP_API_PROXY}/api/properties/${encodeURIComponent(property._id)}`, { 
      method: "DELETE", 
      credentials: "include",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + auth.user.token
      }
    })
    setProperties((properties) => properties.filter((p) => p._id !== property._id))
  }

  useEffect(() => {
    if (auth.user) {
      const fetchPropertiesData = async () => {
        const propertyResponse = await fetch(`${process.env.REACT_APP_API_PROXY}/api/properties`, { 
          method: "GET", 
          credentials: "include",
          headers: { 
            "Content-Type": "application/json",
            "Authorization": "Bearer " + auth.user.token
          }
        })
        const response = await propertyResponse.json()
        setProperties(response)
      }
  
      fetchPropertiesData()
    }
  }, [updated, auth])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box sx={{ alignItems: "center", display: "flex", justifyContent: "space-between", flexWrap: "wrap", m: -1 }}>
          <Typography sx={{ m: 1 }} variant="h4">Real Estate</Typography>
          <Box sx={{ m: 1 }}>
            <PropertyModal actions={{ toggleUpdate }}/>
          </Box>
        </Box>
      </Grid>

      {/* Property map */}
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", height: "100%" }}>
          <PropertyMap properties={properties} />
        </Paper>
      </Grid>

      {/* Property table */}
      <Grid item xs={12}>
        <TableContainer component={Paper} sx={{ height: "100%" }} >
          <Table sx={{border: "1px grey"}}>
            <colgroup>
              <col style={{width:"12%"}} />
              <col style={{width:"30%"}} />
              <col style={{width:"6%"}} />
              <col style={{width:"6%"}} />
              <col style={{width:"6%"}} />
              <col style={{width:"6%"}} />
              <col style={{width:"6%"}} />
              <col style={{width:"6%"}} />
              <col style={{width:"6%"}} />
              <col style={{width:"6%"}} />
              <col style={{width:"10%"}} />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Property Type</TableCell>
                <TableCell>Coordinates</TableCell>
                <TableCell>Market Value</TableCell>
                <TableCell>Book Value</TableCell>
                <TableCell>Listing Status</TableCell>
                <TableCell>Area (sq. ft.)</TableCell>
                <TableCell>Price/Area (USD/sq. ft.)</TableCell>
                <TableCell>Acquisition Date</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>       
              {properties ? properties.map(property => (
                <TableRow key={property._id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell sx={{ fontWeight: "bold" }}>{property.name}</TableCell>
                  <TableCell>{property.address}</TableCell>
                  <TableCell>{property.propertyType}</TableCell>
                  <TableCell>{property.coords?.lat?.toFixed(6)}, {property.coords?.lng?.toFixed(6)}</TableCell>
                  <TableCell>${property.marketValue}</TableCell>
                  <TableCell>${property.bookValue}</TableCell>
                  <TableCell>{property.listingStatus}</TableCell>
                  <TableCell>{property.area}</TableCell>
                  <TableCell>${property.pricePerArea}</TableCell>
                  <TableCell>{moment(property.acquisitionDate).format("YYYY-MM-DD")}</TableCell>
                  <TableCell align="center">
                    <PropertyModal property={property} actions={{ toggleUpdate }} />
                    <DeleteDialog objectName={"property"} deleteFunction={() => deleteProperty(property)} />
                  </TableCell>
                </TableRow>
              )) : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default PropertyPage
