import { createContext, useContext, useEffect, useState } from "react"
import { useLocation, Navigate } from "react-router-dom"

const AuthContext = createContext(null)

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)

  const signin = async (email, password, callback) => {
    const loginResponse = await fetch(`${process.env.REACT_APP_API_PROXY}/api/users/login`, { 
      method: "POST", 
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, password }) 
    })
    const response = await loginResponse.json()
    if (loginResponse.status === 401) callback(false)

    const user = {
      token: response.token,
      expiry: response.expiry,
      _id: response.user._id,
      email: response.user.email,
      firstname: response.user.firstname,
      lastname: response.user.lastname,
      isAdmin: response.user.isAdmin,
      userSettings: response.user.userSettings
    }
    setUser(user)
    localStorage.setItem("user", JSON.stringify(user))
    callback(true, response.user.userSettings?.firstPage)
  }

  const signout = (callback) => {
    setUser(null)
    localStorage.setItem("user", null)
    callback()
  }

  const updateUserSettings = (newSettings) => {
    localStorage.setItem("user", JSON.stringify({...user, userSettings: newSettings}))
  }

  // Try to get user data from local storage when provider is loaded
  useEffect(() => {
    const localUser = JSON.parse(localStorage.getItem("user"))
    if (localUser) { setUser(localUser) }
  }, [])

  const value = { user, signin, signout, updateUserSettings }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const RequireAuth = ({ children }) => {
  const auth = useContext(AuthContext)
  const location = useLocation()
  const localUser = JSON.parse(localStorage.getItem("user"))

  // If there is no logged-in user data (e.g. newly loaded page) and 
  // trying to get from local storage returns nothing
  if ((!auth.user && !localUser) || !localUser.expiry || new Date() > new Date(localUser.expiry)) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children
}

export { AuthContext, AuthProvider, RequireAuth }
