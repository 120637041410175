import { useState, memo, useEffect } from "react"
import { ZoomableGroup, ComposableMap, Geographies, Geography } from "react-simple-maps"
import { scaleLinear } from "d3-scale"
import { theme } from "theme"

const MapChart = ({ setTooltipContent, setCurrentCountry, taskCountPerCountry, height }) => {
  const [maxCountryCount, setMaxCountryCount] = useState(0)
  const [taskCountPerCountryMap, setTaskCountPerCountryMap] = useState({})
  
  const onMouseEnter = (geo) => {
    const taskCountForCountry = 
      taskCountPerCountryMap[geo.properties.name]
      || { "Pending": 0, "Done": 0, "Tasks Overdue": 0 }

    setTooltipContent({
      countryName: geo.properties.name,
      tasksPending: taskCountForCountry["Pending"],
      tasksDone: taskCountForCountry["Done"],
      tasksOverdue: taskCountForCountry["Tasks Overdue"],
    })
  }

  const onClick = (geo) => {
    setCurrentCountry(geo.properties.name)
  }

  const onMouseLeave = () => {
    setTooltipContent()
  }

  const linearColorScale = scaleLinear()
    .domain([0, 1])
    .range([theme.palette.error.main, theme.palette.success.main])

  const getCountryColor = (countryName) => {
    const countryCount = taskCountPerCountry.find(c => c._id === countryName)
    return countryCount ? linearColorScale(countryCount["Done"] / countryCount.count) : "#D6D6DA"
  }

  useEffect(() => {
    if (taskCountPerCountry.length !== 0) {
      setMaxCountryCount(Math.max(...taskCountPerCountry.map(country => country.count)))
      setTaskCountPerCountryMap(taskCountPerCountry.reduce((map, country) => {
        map[country._id] = {
          "Pending": country["Pending"],
          "Done": country["Done"],
          "Tasks Overdue": country["Tasks Overdue"],
        }
        return map
      }))
    }
  }, [taskCountPerCountry, maxCountryCount])

  return (
    <div data-tip="">
      <ComposableMap height={height} style={{borderColor:"black", borderWidth: 1}}>
        <ZoomableGroup center={[14, 8]}>
          <Geographies geography="/features.json">
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onClick={() => onClick(geo)}
                  onMouseEnter={() => onMouseEnter(geo)}
                  onMouseLeave={() => onMouseLeave()}
                  style={{
                    default: {
                      fill: getCountryColor(geo.properties.name),
                      stroke: "#F6F6FA",
                      outline: "none"
                    },
                    hover: {
                      fill: "#F53",
                      outline: "none"
                    },
                    pressed: {
                      fill: "#E42",
                      outline: "none"
                    }
                  }}
                />
              ))
            }
          </Geographies>
          {/* <Marker coordinates={[-74.006, 40.7128]}>
            <circle r={1} fill="#F53" />
          </Marker> */}
        </ZoomableGroup>
      </ComposableMap>
    </div>
  )
}

export default memo(MapChart)
