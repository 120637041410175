import "chart.js/auto"
import { useState, useEffect, useContext } from "react"
import { 
  Grid, Box, Typography, Button, Card, CardContent,
  Tabs, Tab, LinearProgress, Alert
} from "@mui/material"
import { styled } from "@mui/material/styles"
import { DataGrid } from '@mui/x-data-grid'
import ReturnsModal from "components/returns/ReturnsModal"
import { AuthContext } from "helpers/auth"

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  width: 200,
  borderRadius: 5,
}));

const getQuarterThreshold = (quarterValue, thresholdPerQuarter) => {
  const percentage = (((quarterValue / thresholdPerQuarter) || 0) * 100).toFixed(2) || 0
  
  let likelihood = "Unlikely"
  let color = "success.main"
  if (percentage >= 50 && percentage < 80) {
    likelihood = "Likely"
    color = "warning.main"
  } else if (percentage >= 80) {
    likelihood = "Highly Likely"
    color = "error.main"
  }
  
  return (
    <>
      <Typography variant="caption" sx={{ color: color }}>({percentage}%, {likelihood})</Typography>
    </>
  )
}

const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  )
}

const renderQuarterlyCell = (params) => {
  const value = params.value?.toFixed(0) || 0
  const thresholdPerQuarter = (params.row["Annual Turnover Threshold for VAT Registration (USD)"] / 4)
  return (
    <Box sx={{ width: "100%", py: 2 }}>
      <div>{value?.toLocaleString()}</div>
      <div>{getQuarterThreshold(value, thresholdPerQuarter)}</div>
      <div>
        <BorderLinearProgress variant="determinate" value={isNaN(value / thresholdPerQuarter) ? 0 : Math.min((value / thresholdPerQuarter) * 100, 100)} />
      </div>
    </Box>
  )
}

const renderAnnualCell = (params) => {
  const value = params.value?.toFixed(0) || 0
  const threshold = params.row["Annual Turnover Threshold for VAT Registration (USD)"]
  return (
    <Box sx={{ width: "100%", py: 2 }}>
      <div>{value?.toLocaleString()}</div>
      <div>{getQuarterThreshold(value, threshold)}</div>
      <div>
        <BorderLinearProgress variant="determinate" value={isNaN(value / threshold) ? 0 : Math.min((value / threshold) * 100, 100)} />
      </div>
    </Box>
  )
}

const ResidencePage = () => {
  const [returnsData, setReturnsData] = useState(null)
  const [quarterKeys, setQuarterKeys] = useState(null)

  const [dataGridColumnsQuarterly, setDataGridColumnsQuarterly] = useState([])
  const [dataGridRowsQuarterly, setDataGridRowsQuarterly] = useState([])
  const [dataGridColumnsAnnual, setDataGridColumnsAnnual] = useState([])
  const [dataGridRowsAnnual, setDataGridRowsAnnual] = useState([])

  const auth = useContext(AuthContext)

  const [tabValue, setTabValue] = useState(0)
  const handleTabChange = (event, newValue) => { setTabValue(newValue) }

  useEffect(() => {
    if (auth.user && !returnsData) {
      const fetchReturnsData = async () => {
        const subtaskResponse = await fetch(`${process.env.REACT_APP_API_PROXY}/api/returns/`, { 
          method: "GET", 
          credentials: "include",
          headers: { 
            "Content-Type": "application/json",
            "Authorization": "Bearer " + auth.user.token
          }
        })
        const response = await subtaskResponse.json()
        setReturnsData(response.returns.filter(r => r["EU/NON EU"] !== "EU" && r["VAT Registered"] !== "Registered"))
      }
      fetchReturnsData()
    }
  }, [auth.user, returnsData])

  useEffect(() => {
    if (returnsData) {
      const sliceStart = 10
      const firstItem = returnsData[0]

      const annualPercentageCompare = (v1, v2, param1, param2) => {
        const threshold1String = returnsData.find(r => r["Country Code"] === param1.id)
        const threshold1 = typeof threshold1String["Annual Turnover Threshold for VAT Registration (USD)"] === "number"
                              ? threshold1String["Annual Turnover Threshold for VAT Registration (USD)"] : Infinity
        
        const threshold2String = returnsData.find(r => r["Country Code"] === param2.id)
        const threshold2 = typeof threshold2String["Annual Turnover Threshold for VAT Registration (USD)"] === "number"
                              ? threshold2String["Annual Turnover Threshold for VAT Registration (USD)"] : Infinity
      
        const v1Percentage = (v1 / threshold1) || 0 
        const v2Percentage = (v2 / threshold2) || 0
      
        return v1Percentage - v2Percentage
      }

      // Annual returns data
      const dataGridColumnsAnnual = [
        { field: "Country Name", headerName: "Country Name", width: 250 },
        { field: "Country Code", headerName: "Country Code", width: 150 },
        {
          field: "Annual Turnover Threshold for VAT Registration (USD)",
          headerName: "Annual Turnover Threshold for VAT Registration (USD)",
          width: 200,
        }
      ]

      Object.keys(returnsData[0]).slice(sliceStart).filter((value, index) => ((index - 20) % 11 === 0 && index !== 0)).forEach((key) => {
        dataGridColumnsAnnual.push({
          field: key,
          headerName: key,
          width: 250,
          renderCell: renderAnnualCell,
          sortComparator: annualPercentageCompare,
          // Column is hidden by default if column name does not contain current year in string
          hide: !key.includes(new Date().getFullYear().toString()),
        })
      })

      const dataGridRowsAnnual = returnsData.map((row) => {
        const rowData = {
          id: row["Country Code"],
          "Country Name": row["Country Name"],
          "Country Code": row["Country Code"],
          "Annual Turnover Threshold for VAT Registration (USD)": typeof row["Annual Turnover Threshold for VAT Registration (USD)"] === "number" 
            ? row["Annual Turnover Threshold for VAT Registration (USD)"].toFixed(0) : Infinity,
        }

        Object.keys(returnsData[0]).slice(sliceStart).filter((value, index) => ((index - 20) % 11 === 0 && index !== 0)).forEach((key) => {
          rowData[key] = row[key]
        })

        return rowData
      })

      setDataGridRowsAnnual(dataGridRowsAnnual)
      setDataGridColumnsAnnual(dataGridColumnsAnnual)

      // Quarterly returns data
      const q1Key = Object.keys(firstItem)[Object.keys(firstItem).length - 7]
      const q2Key = Object.keys(firstItem)[Object.keys(firstItem).length - 6]
      const q3Key = Object.keys(firstItem)[Object.keys(firstItem).length - 5]
      const q4Key = Object.keys(firstItem)[Object.keys(firstItem).length - 4]
      const yearTotalKey = Object.keys(firstItem)[Object.keys(firstItem).length - 2]
      setQuarterKeys({ q1Key, q2Key, q3Key, q4Key, yearTotalKey })

      const quarterPercentageCompare = (v1, v2, param1, param2) => {
        const threshold1String = returnsData.find(r => r["Country Code"] === param1.id)
        const threshold1 = typeof threshold1String["Annual Turnover Threshold for VAT Registration (USD)"] === "number"
                              ? threshold1String["Annual Turnover Threshold for VAT Registration (USD)"] / 4 : Infinity
        
        const threshold2String = returnsData.find(r => r["Country Code"] === param2.id)
        const threshold2 = typeof threshold2String["Annual Turnover Threshold for VAT Registration (USD)"] === "number"
                              ? threshold2String["Annual Turnover Threshold for VAT Registration (USD)"] / 4 : Infinity
      
        const v1Percentage = (v1 / threshold1) || 0 
        const v2Percentage = (v2 / threshold2) || 0
      
        return v1Percentage - v2Percentage
      }

      const dataGridColumnsQuarterly = [
        { field: "Country Name", headerName: "Country Name", width: 250 },
        { field: "Country Code", headerName: "Country Code", width: 150 },
        { 
          field: "Threshold per Quarter (USD)", 
          headerName: "Threshold per Quarter (USD)", 
          width: 200,
          valueGetter: (params) => {
            const thresholdPerQuarter = typeof params.row["Annual Turnover Threshold for VAT Registration (USD)"] === "number" 
                                             ? (params.row["Annual Turnover Threshold for VAT Registration (USD)"] / 4).toFixed(0) : Infinity
            return thresholdPerQuarter
          }
        },
        {
          field: "VAT Registered",
          headerName: "VAT Registered",
          width: 150,
        },
        { 
          field: q1Key, 
          headerName: q1Key, 
          width: 250,
          renderCell: (params) => (renderQuarterlyCell(params)),
          sortComparator: quarterPercentageCompare,
        },
        { 
          field: q2Key, 
          headerName: q2Key, 
          width: 250,
          hide: !(new Date().getMonth() > 3), // show only on April onwards
          renderCell: (params) => (renderQuarterlyCell(params)),
          sortComparator: quarterPercentageCompare,
        },
        { 
          field: q3Key, 
          headerName: q3Key, 
          width: 250,
          hide: !(new Date().getMonth() > 6), // show only on July onwards
          renderCell: (params) => (renderQuarterlyCell(params)),
          sortComparator: quarterPercentageCompare,
        },
        { 
          field: q4Key, 
          headerName: q4Key, 
          width: 250,
          hide: !(new Date().getMonth() > 9), // show only on October onwards
          renderCell: (params) => (renderQuarterlyCell(params)),
          sortComparator: quarterPercentageCompare,
        },
        {
          field: yearTotalKey,
          headerName: yearTotalKey,
          width: 250,
          renderCell: (params) => (renderAnnualCell(params)),
        },
      ]

      const dataGridRowsQuarterly = returnsData.map((row) => ({
        id: row["Country Code"],
        "Country Name": row["Country Name"],
        "Country Code": row["Country Code"],
        "Annual Turnover Threshold for VAT Registration (USD)": row["Annual Turnover Threshold for VAT Registration (USD)"],
        "VAT Registered": row["VAT Registered"],
        [q1Key]: row[q1Key],
        [q2Key]: row[q2Key],
        [q3Key]: row[q3Key],
        [q4Key]: row[q4Key],
        [yearTotalKey]: row[yearTotalKey],
      }))

      setDataGridColumnsQuarterly(dataGridColumnsQuarterly)
      setDataGridRowsQuarterly(dataGridRowsQuarterly)
    }
  }, [returnsData])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box sx={{ alignItems: "center", display: "flex", justifyContent: "space-between", flexWrap: "wrap", m: -1 }}>
          <Typography sx={{ m: 1 }} variant="h4">VAT Analysis</Typography>
          <Box sx={{ m: 1 }}>
            <Button color="primary" variant="contained" href="/returns_template.xlsx" sx={{ mr: 1 }}>
              Download Template
            </Button>
            <ReturnsModal actions={{ setReturnsData }} />
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Alert severity="info" sx={{ alignItems: "center", display: "flex" }}>
          <div>A threshold of 0 means VAT registration must be done regardless of revenue amount.</div>
          <div>A threshold of Infinity means VAT registration is not required regardless of revenue amount.</div>
        </Alert>
      </Grid>

      { returnsData && quarterKeys
      ?
        <Grid item xs={12} >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label="Annual" />
              <Tab label="Quarterly" />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <Card sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
              { returnsData
                ?
                  <CardContent>
                    <Grid container>
                      <Grid item xs={12} sx={{ px: 1 }}>
                        <Typography variant="h6" sx={{ pb: 1 }}>Analysis per year</Typography>
                      </Grid>
                      <Grid item xs={12} sx={{ px: 1, height: "1000px" }}>
                        <DataGrid
                          rows={dataGridRowsAnnual}
                          columns={dataGridColumnsAnnual}
                          pageSize={50}
                          rowsPerPageOptions={[50]}
                          getRowHeight={() => 'auto'}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                :
                  null
              }
            </Card>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Card sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
              { returnsData && quarterKeys
                ?
                  <CardContent>
                    <Grid container>
                      <Grid item xs={12} sx={{ px: 1 }}>
                        <Typography variant="h6" sx={{ pb: 1 }}>Analysis per quarter (latest year)</Typography>
                      </Grid>
                      <Grid item xs={12} sx={{ px: 1, height: "1000px" }}>
                        <DataGrid
                          rows={dataGridRowsQuarterly}
                          columns={dataGridColumnsQuarterly}
                          pageSize={50}
                          rowsPerPageOptions={[50]}
                          getRowHeight={() => 'auto'}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                :
                  null
              }
            </Card>
          </TabPanel>
        </Grid>
      : null }
    </Grid>
  )
}

export default ResidencePage
