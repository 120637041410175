import { Fragment, useState, useContext } from "react"
import { Button, Modal, Box, Divider, Grid, MenuItem, IconButton } from "@mui/material"
import { Edit as EditIcon } from "@mui/icons-material"
import { ValidatorForm, SelectValidator } from "react-material-ui-form-validator"
import { AuthContext } from "helpers/auth"
// import COUNTRIES from "helpers/countries"
import { YEARS, QUARTERS, FILING_STATUSES, FILING_TYPES, FILING_COUNTRIES as COUNTRIES } from "helpers/constants"
import { SnackbarContext } from "helpers/snackbar"

const FilingsModal = ({ filing, actions }) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    if (!filing) {
      setCurrentFiling({
        country: "",
        filingType: FILING_TYPES[0],
        year: "",
        quarter: "",
        status: "",
      })
    }

    setOpen(false)
    actions.toggleUpdate() 
  }

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  }

  const [currentFiling, setCurrentFiling] = useState(
    filing ? filing 
    : {
      country: "",
      filingType: FILING_TYPES[0],
      year: "",
      quarter: "",
      status: "",
  })

  const filingPropertyOnChange = (e) => setCurrentFiling({...currentFiling, [e.target.name]: e.target.value})
  const auth = useContext(AuthContext)
  const snackbar = useContext(SnackbarContext)

  const handleSubmit = async () => {
    try {
      const url = filing ? `${process.env.REACT_APP_API_PROXY}/api/filings/${encodeURIComponent(filing._id)}` : `${process.env.REACT_APP_API_PROXY}/api/filings`
      const method = filing ? "PUT" : "POST"
      await fetch(url, { 
        method: method, 
        credentials: "include",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": "Bearer " + auth.user.token
        },
        body: JSON.stringify(currentFiling) 
      })
      snackbar.setMessage({ message: `Filing successfully ${filing ? "updated" : "created"}!`, severity: "success" })
      handleClose()
    } catch (error) {
      console.log(error)
      snackbar.setMessage({ message: error.message, severity: "error" })
    }
  }

  return (
    <Fragment>
      {filing 
        ?
          <IconButton variant="contained" onClick={handleOpen}>
            <EditIcon />
          </IconButton>
        :
          <Button 
            variant="contained" 
            sx={{ height: "100%", width: "100%" }}
            onClick={handleOpen}
          >
            Add Filing
          </Button>
      }
      <Modal open={open} onClose={handleClose}>
        <Box sx={boxStyle}>
          <ValidatorForm onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Header */}
              <Grid item xs={12}>
                {filing ? "Edit Filing" : "Create Filing"}
                <Divider sx={{ p: 1 }} />  
              </Grid>

              {/* Country */}
              <Grid item xs={2}>
                <SelectValidator
                  fullWidth
                  label="Country"
                  name="country"
                  value={currentFiling.country}
                  onChange={filingPropertyOnChange}
                  validators={["required"]}
                  errorMessages={["Country is required"]}
                >
                  {COUNTRIES.map((country) => (
                    <MenuItem key={country} value={country}>
                      {country}
                    </MenuItem>
                  ))}
                </SelectValidator>
              </Grid>

              {/* Filing Type */}
              <Grid item xs={2}>
                <SelectValidator
                  fullWidth
                  label="Filing Type"
                  name="filingType"
                  value={currentFiling.filingType}
                  onChange={filingPropertyOnChange}
                  validators={["required"]}
                  errorMessages={["Filing Type is required"]}
                >
                  {FILING_TYPES.map((filingType) => (
                    <MenuItem key={filingType} value={filingType}>
                      {filingType}
                    </MenuItem>
                  ))}
                </SelectValidator>
              </Grid>

              {/* Year */}
              <Grid item xs={2}>
                <SelectValidator
                  fullWidth
                  label="Year"
                  name="year"
                  value={currentFiling.year}
                  onChange={filingPropertyOnChange}
                  validators={["required"]}
                  errorMessages={["Year is required"]}
                >
                  {YEARS.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </SelectValidator>
              </Grid>

              {/* Quarter */}
              <Grid item xs={2}>
                <SelectValidator
                  fullWidth
                  label="Quarter"
                  name="quarter"
                  value={currentFiling.quarter}
                  onChange={filingPropertyOnChange}
                  validators={["required"]}
                  errorMessages={["Quarter is required"]}
                >
                  {QUARTERS.map((quarter) => (
                    <MenuItem key={quarter} value={quarter}>
                      {quarter}
                    </MenuItem>
                  ))}
                </SelectValidator>
              </Grid>

              {/* Status */}
              <Grid item xs={2}>
                <SelectValidator
                  fullWidth
                  label="Status"
                  name="status"
                  value={currentFiling.status}
                  onChange={filingPropertyOnChange}
                  validators={["required"]}
                  errorMessages={["Status is required"]}
                >
                  {FILING_STATUSES.map((status) => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </SelectValidator>
              </Grid>

              {/* Submit */}
              <Grid item xs={2}>
                <Button 
                  variant="contained" 
                  type="submit" 
                  sx={{ height: "100%", width: "100%" }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        </Box>
      </Modal>
    </Fragment>
  )
}

export default FilingsModal
