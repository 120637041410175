import { useState, useEffect, Fragment, useContext } from "react"
import { Grid, Paper, Select, MenuItem, InputLabel, FormControl, Typography, Box } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import COUNTRIES from "helpers/countries"
import TaskModal from "components/tasks/TaskModal"
import ObligationModal from "components/obligations/ObligationModal"
import ImportModal from "components/files/ImportModal"
import DeleteDialog from "components/common/DeleteDialog"
import { AuthContext } from "helpers/auth"

const ObligationPage = () => {
  const [obligationList, setObligationList] = useState([])
  const [currentCountry, setCurrentCountry] = useState(COUNTRIES[0])
  const [updated, setUpdated] = useState(0)
  const auth = useContext(AuthContext)
  const toggleUpdate = () => setUpdated(!updated)

  const obligationColumns = [
    { 
      field: "action",
      headerName: "Actions",
      sortable: false,
      minWidth: 150,
      renderCell: (params) => (
        <Fragment>
          <TaskModal obligation={params.row} />
          <ObligationModal country={currentCountry} obligation={params.row} actions={{ toggleUpdate }} />
          <DeleteDialog objectName={"obligation"} deleteFunction={() => deleteObligation(params.row)} />
        </Fragment>
      )
    },
    { field: "name", headerName: "Name", minWidth: 200 },
    { field: "prepareOfFile", headerName: "Prepare of File", minWidth: 150 },
    { field: "format", headerName: "Format", minWidth: 200 },
    { field: "deadlineFormat", headerName: "Deadline Format", minWidth: 350 },
    { field: "notificationDeadlineFormat", headerName: "Notification Deadline", minWidth: 180 },
    { field: "threshold", headerName: "Threshold", minWidth: 300 },
    { field: "localLanguage", headerName: "Local Language", minWidth: 300 }
  ]

  const deleteObligation = async (obligation) => {
    await fetch(`${process.env.REACT_APP_API_PROXY}/api/obligations/${encodeURIComponent(obligation._id)}`, { 
      method: "DELETE", 
      credentials: "include",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + auth.user.token
      }
    })
    setObligationList((obligationList) => obligationList.filter((o) => o._id !== obligation._id))
  }

  useEffect(() => {
    if (auth.user) {
      const fetchObligationData = async () => {      
        const obligationResponse = await fetch(`${process.env.REACT_APP_API_PROXY}/api/obligations?country=${encodeURIComponent(currentCountry)}`, { 
          method: "GET", 
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + auth.user.token
          }
        })
        const obligations = await obligationResponse.json()
        setObligationList(obligations) 
      }
  
      fetchObligationData()
    }
  }, [currentCountry, updated, auth])

  return (
    <Grid container spacing={3}>
      {/* Header and add button */}
      <Grid item xs={12}>
        <Box sx={{ alignItems: "center", display: "flex", justifyContent: "space-between", flexWrap: "wrap", m: -1 }}>
          <Typography sx={{ m: 1 }} variant="h4">Obligations</Typography>
          <Box sx={{ m: 1 }}>
            <ImportModal actions={{ toggleUpdate }} />
            <ObligationModal country={currentCountry} actions={{ toggleUpdate }} />
          </Box>
        </Box>
      </Grid>

      {/* Country select */}
      <Grid item xs={4}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", height: "100%" }}>
          <FormControl>
            <InputLabel>Country</InputLabel>
            <Select label="Country" value={currentCountry} onChange={(e) => {setCurrentCountry(e.target.value)}}>
              { COUNTRIES.map(country => {
                return (
                  <MenuItem key={country} value={country}>{country}</MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Paper>
      </Grid>

      {/* Obligations per country */}
      <Grid item xs={12} sx={{ height: 550 }}>
        <DataGrid
          rows={obligationList}
          columns={obligationColumns}
          getRowId={(row) => row._id}
          disableSelectionOnClick
        />
      </Grid>
    </Grid>
  )
}

export default ObligationPage
