import { Fragment, useEffect, useState, useContext } from "react"
import {  Grid, Box, Paper, Typography, Chip } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import StatusCell from "components/tasks/StatusCell"
import SubtaskModal from "components/tasks/subtasks/SubtaskModal"
import TaskFileModal from "components/tasks/TaskFileModal"
import DeleteDialog from "components/common/DeleteDialog"
import { AuthContext } from "helpers/auth"
import { UsersContext } from "helpers/users"

const SubtasksComponent = ({ task }) => {
  const [subtasks, setSubtasks] = useState([])
  const [updated, setUpdated] = useState(0)
  const auth = useContext(AuthContext)
  const users = useContext(UsersContext)
  const toggleUpdate = () => setUpdated(!updated)
  
  const subtaskColumns = [
    { 
      field: "name", 
      headerName: "Name", 
      flex: 1 
    },
    { 
      field: "status", 
      headerName: "Status", 
      flex: 1,
      renderCell: (params) => (<StatusCell status={params.row.status} />)
    },
    { 
      field: "deadlineDate", 
      headerName: "Deadline", 
      flex: 1,
      type: "date",
      valueGetter: (params) => (new Date(params.row.deadlineDate)),
      renderCell: (params) => (new Date(params.row.deadlineDate).toDateString())
    },
    { 
      field: "assignedUsers", 
      headerName: "Assigned Users", 
      sortable: false,
      minWidth: 300, 
      flex: 1,
      renderCell: (params) => 
        (params.row.assignedUsers.map((user) => (
          <Chip sx={{ mr: 1 }} key={user} label={users.findNameFromId(user)}/>
        )))
    },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <Fragment>
          <TaskFileModal task={task} subtask={params.row} actions={{ toggleUpdate }}/>
          <SubtaskModal task={task} subtask={params.row} actions={{ toggleUpdate }}/>
          <DeleteDialog objectName={"subtask"} deleteFunction={() => deleteSubtask(params.row)} />
        </Fragment>
      )
    }
  ]

  const deleteSubtask = async (subtask) => {
    await fetch(`${process.env.REACT_APP_API_PROXY}/api/tasks/${encodeURIComponent(task._id)}/subtasks/${encodeURIComponent(subtask._id)}`, { 
      method: "DELETE", 
      credentials: "include",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + auth.user.token
      }
    })
    setSubtasks((subtasks) => subtasks.filter((s) => s._id !== subtask._id))
  }

  useEffect(() => {
    if (auth.user) {
      const fetchSubtaskData = async () => {
        const subtaskResponse = await fetch(`${process.env.REACT_APP_API_PROXY}/api/tasks/${encodeURIComponent(task._id)}/subtasks`, { 
          method: "GET", 
          credentials: "include",
          headers: { 
            "Content-Type": "application/json",
            "Authorization": "Bearer " + auth.user.token
          }
        })
        const response = await subtaskResponse.json()
        setSubtasks(response.subtasks)
      }
  
      fetchSubtaskData()
    }
  }, [auth.user, updated, task._id])

  return ( 
    <Paper elevation={6} sx={{ px: 2, pt: 1, pb: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
      {/* Subtasks header and add button */}
      <Grid container alignItems="center">
        <Grid item xs={9}>
          <Typography variant="subtitle2" component="div">Subtasks</Typography>
        </Grid>
        <Grid item xs={3}>
          <Box sx={{ display: "flex", justifyContent: "flex-end"}}>
            <SubtaskModal task={task} actions={{ toggleUpdate }}/>
          </Box>
        </Grid>
      </Grid>

      {/* Subtasks main content */}
      <Box sx={{ height: 400, width: "100%", pt: 1 }}>
        <DataGrid 
          rows={subtasks}
          columns={subtaskColumns}
          getRowId={(row) => row._id}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </Box>
    </Paper>
  )
}
 
export default SubtasksComponent
