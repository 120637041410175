const PHASES = ["Preparation", "Filing", "Approval"]
const PERIODS = ["Monthly", "Quarterly", "Annual"]
const TYPES = ["Statutory Requirement", "Other Requirement"]
const STATUSES = ["Pending", "Done", "Tasks Overdue"]

const YEARS = ["2018", "2019", "2020", "2021", "2022", "2023", "2024"]
const QUARTERS = ["Q1", "Q2", "Q3", "Q4"]
const FILING_STATUSES = ["Not Started", "Prepared", "Submitted", "Paid", "Confirmed"]
const FILING_TYPES = ["VAT", "Annual Accounts", "Corporate Income Tax"]

const FILING_COUNTRIES = [
  'Algeria',
  'Angola',
  'Argentina',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahrain',
  'Belarus',
  'Belgium',
  'Bolivia',
  'Brazil',
  'Bulgaria',
  'Burkina Faso',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Chile',
  'China',
  'Colombia',
  'Costa Rica',
  'Croatia',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Estonia',
  'European Union',
  'Finland',
  'France',
  'Gabon',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Guatemala',
  'Haiti',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Ireland',
  'Israel',
  'Italy',
  'Ivory Coast',
  'Japan',
  'Kazakhstan',
  'Kenya',
  'Latvia',
  'Lithuania',
  'Luxembourg',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Mexico',
  'Morocco',
  'Mozambique',
  'Namibia',
  'The Netherlands',
  'New Zealand',
  'Nigeria',
  'Norway',
  'Oman',
  'Pakistan',
  'Panama',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russia',
  'Saudi Arabia',
  'Senegal',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'South Africa',
  'South Korea',
  'Spain',
  'Sri Lanka',
  'Sweden',
  'Switzerland',
  'Taiwan',
  'Tanzania',
  'Thailand',
  'Turkey',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'Uruguay',
  'United States',
  'Venezuela',
  'Vietnam',
  'Zambia'
]

const EU_COUNTRIES = [
  'Austria',
  'Belgium',
  'Bulgaria',
  'Croatia',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Estonia',
  'Finland',
  'France',
  'Germany',
  'Greece',
  'Hungary',
  'Ireland',
  'Italy',
  'Latvia',
  'Lithuania',
  'Luxembourg',
  'Malta',
  'The Netherlands',
  'Poland',
  'Portugal',
  'Romania',
  'Slovakia',
  'Slovenia',
  'Spain',
  'Sweden'
]

export { PHASES, PERIODS, TYPES, STATUSES, YEARS, QUARTERS, FILING_STATUSES, FILING_TYPES, FILING_COUNTRIES, EU_COUNTRIES }
