import { Fragment, useState, useContext, useEffect } from "react"
import { Button, Modal, Box, Divider, Grid, IconButton, Checkbox, FormControlLabel } from "@mui/material"
import { Edit as EditIcon } from "@mui/icons-material"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import { AuthContext } from "helpers/auth"

const UserModal = ({ user, actions }) => {
  const [open, setOpen] = useState(false)
  const [changePassword, setChangePassword] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    actions.toggleUpdate()
    if (!user) { 
      setFirstname("")
      setLastname("")
      setEmail("")
      setIsAdmin(false)
    } else if (user.email === auth.user.email) {
      setChangePassword(false)
      setOldPassword("")
      setNewPassword("")
      setNewPasswordConfirm("")
      setOldPasswordError(false)
    }
  }

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  }

  const [firstname, setFirstname] = useState(user ? user.firstname : "")
  const [lastname, setLastname] = useState(user ? user.lastname : "")
  const [email, setEmail] = useState(user ? user.email : "")
  const [isAdmin, setIsAdmin] = useState(user ? user.isAdmin : false)

  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("")
  const [oldPasswordError, setOldPasswordError] = useState(false)

  const auth = useContext(AuthContext)

  const handleSubmit = async () => {
    try {
      const url = user ? `${process.env.REACT_APP_API_PROXY}/api/users/${encodeURIComponent(user._id)}` : `${process.env.REACT_APP_API_PROXY}/api/users/register`
      const method = user ? "PUT" : "POST"
      let body = { firstname, lastname, email }
      if (changePassword) body = { ...body, oldPassword, newPassword }
      if (auth.user.isAdmin) body = { ...body, isAdmin }

      const updateUserResponse = await fetch(url, { 
        method: method, 
        credentials: "include",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": "Bearer " + auth.user.token
        },
        body: JSON.stringify(body) 
      })

      if (updateUserResponse.status === 401) {
        setOldPasswordError(true)
      } else {
        handleClose()
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {return value === newPassword})
  }, [newPassword])

  return (
    <Fragment>
      {user ? 
        <IconButton variant="contained" onClick={handleOpen}>
          <EditIcon />
        </IconButton> 
      : 
        <Button color="primary" variant="contained" onClick={handleOpen}>
          Add User
        </Button>
      }
      <Modal open={open} onClose={handleClose}>
        <Box sx={boxStyle}>
          <ValidatorForm onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Header */}
              <Grid item xs={12}>
              {user ? "Edit" : "New"} user
                <Divider sx={{ p: 1 }} />  
              </Grid>
            
              {/* First name */}
              <Grid item xs={6}>
                <TextValidator
                  fullWidth
                  variant="outlined"
                  label="First Name"
                  value={firstname}
                  validators={["required"]}
                  errorMessages={["This field is required."]}
                  onChange={(e) => setFirstname(e.target.value)} 
                />
              </Grid>

              {/* Last name */}
              <Grid item xs={6}>
                <TextValidator
                  fullWidth
                  variant="outlined"
                  label="Last Name"
                  value={lastname}
                  validators={["required"]}
                  errorMessages={["This field is required."]}
                  onChange={(e) => setLastname(e.target.value)} 
                />
              </Grid>

              {/* Email */}
              <Grid item xs={12}>
                <TextValidator
                  fullWidth
                  variant="outlined"
                  label="Email"
                  value={email}
                  validators={["required"]}
                  errorMessages={["This field is required."]}
                  onChange={(e) => setEmail(e.target.value)} 
                />
              </Grid>

              {/* Admin user checkbox */}
              {auth.user && auth.user.isAdmin ?
                <Grid item xs={12}>
                  <FormControlLabel 
                    control={<Checkbox />} 
                    label="Admin user"
                    checked={isAdmin}
                    onChange={(e) =>{setIsAdmin(e.target.checked)}}
                  />
                </Grid>
              : null}
              
              {/* Change password checkbox */}
              {user && auth.user.email === user.email ? (
                <Grid item xs={12}>
                  <FormControlLabel 
                    control={<Checkbox />} 
                    label="Change password"
                    checked={changePassword}
                    onChange={(e) =>{setChangePassword(e.target.checked)}}
                  />
                </Grid>
              ) : null}

              {changePassword ? 
                <Fragment>
                  <Grid item xs={4}>
                    <TextValidator
                      fullWidth
                      variant="outlined"
                      label="Old Password"
                      value={oldPassword}
                      type="password"
                      validators={["required"]}
                      errorMessages={["This field is required."]}
                      onChange={(e) => setOldPassword(e.target.value)}
                      error={oldPasswordError}
                      helperText={oldPasswordError ? "Incorrect password, please type it again." : ""}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextValidator
                      fullWidth
                      variant="outlined"
                      label="New Password"
                      value={newPassword}
                      type="password"
                      validators={["required"]}
                      errorMessages={["This field is required."]}
                      onChange={(e) => setNewPassword(e.target.value)} 
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextValidator
                      fullWidth
                      variant="outlined"
                      label="New Password Confirmation"
                      value={newPasswordConfirm}
                      type="password"
                      validators={["isPasswordMatch", "required"]}
                      errorMessages={["Password mismatch.", "This field is required."]}
                      onChange={(e) => setNewPasswordConfirm(e.target.value)} 
                    />
                  </Grid>
                </Fragment> 
              : null}

              {/* Submit */}
              <Grid item xs={12}>
                <Button variant="contained" type="submit">Submit</Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        </Box>
      </Modal>
    </Fragment>
  )
}

export default UserModal
