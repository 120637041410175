import { useState } from "react"
import { 
  Grid, Typography, Box, Paper, FormControl, 
  InputLabel, Select, MenuItem, Button 
} from "@mui/material"
import BasicDropzone from "components/files/BasicDropzone"
import FileDataGrid from "components/files/FileDataGrid"

import COUNTRIES from "helpers/countries"
import { YEARS, QUARTERS } from "helpers/constants"

const FilePage = () => {
  const [updated, setUpdated] = useState(0)
  const [filters, setFilters] = useState({
    country: "",
    year: "",
    quarter: "",
  })
  const toggleUpdate = () => setUpdated(!updated)

  // Extend datagrid with date columns
  const dateColumns = [
    { field: "country", headerName: "Country", minWidth: 200 },
    { field: "year", headerName: "Year", minWidth: 200 },
    { field: "quarter", headerName: "Quarter", minWidth: 200 },
  ]

  return (
    <Grid container spacing={3}>
      {/* Header and add button */}
      <Grid item xs={12}>
        <Box sx={{ alignItems: "center", display: "flex", justifyContent: "space-between", flexWrap: "wrap", m: -1 }}>
          <Typography sx={{ m: 1 }} variant="h4">Files</Typography>
        </Box>
      </Grid>

      <Grid item xs={4}>
        <BasicDropzone actions={{ toggleUpdate }} date={filters} />
      </Grid>

      {/* Filters */}
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", height: "100%" }}>
          <Grid container spacing={2}>
            {/* Country filter */}
            <Grid item xs={3}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel shrink>Country</InputLabel>
                <Select displayEmpty notched label="Country" value={filters.country} onChange={(e) => {setFilters({...filters, country: e.target.value})}}>
                  <MenuItem key={"All Countries"} value="">All Countries</MenuItem>
                  { COUNTRIES.map(country => {
                    return (
                      <MenuItem key={country} value={country}>{country}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>

            {/* Year filter */}
            <Grid item xs={3}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel shrink>Year</InputLabel>
                <Select displayEmpty notched label="Year" value={filters.year} onChange={(e) => {setFilters({...filters, year: e.target.value})}}>
                  <MenuItem key={"All Years"} value={""}>All Years</MenuItem>
                  { YEARS.map(year => {
                    return (
                      <MenuItem key={year} value={year}>{year}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>

            {/* Quarter filter */}
            <Grid item xs={3}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel shrink>Quarter</InputLabel>
                <Select displayEmpty notched label="Quarter" value={filters.quarter} onChange={(e) => {setFilters({...filters, quarter: e.target.value})}}>
                  <MenuItem key={"All Quarters"} value="">All Quarters</MenuItem>
                  { QUARTERS.map(quarter => {
                    return (
                      <MenuItem key={quarter} value={quarter}>{quarter}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>

            {/* Clear all filters */}
            <Grid item xs={3}>
              <Button 
                variant="outlined" 
                sx={{ height: "100%", width: "100%" }}
                onClick={() => {
                  setFilters({
                    country: "",
                    year: "",
                    quarter: "",
                  })
                }}
              >
                Clear filters
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      {/* Files */}
      <Grid item xs={12} sx={{ height: 550 }}>
        <FileDataGrid updated={updated} filters={filters} dateColumns={dateColumns} />
      </Grid>
    </Grid>
  )
}

export default FilePage
