import { Fragment, useState } from "react"
import { Button, Modal, Box, Divider, Grid, Typography } from "@mui/material"
import ReturnsDropzone from "components/returns/ReturnsDropzone"

const ReturnsModal = ({ actions }) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    maxHeight: "900px",
    overflow: "scroll"
  }

  return (
    <Fragment>
      <Button color="primary" variant="contained" sx={{ mr: 1 }} onClick={handleOpen}>
        Upload Sales Data
      </Button>

      <Modal open={open} onClose={handleClose}>
        <Box sx={boxStyle}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5">Upload quarterly returns from an Excel file</Typography>
              <Divider sx={{ p: 1 }} />  
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1"  sx={{ my: 1 }} gutterBottom>
                Upload the filled-up template below.
              </Typography>
              <ReturnsDropzone actions={{ ...actions, handleClose }} />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Fragment>
  )
}

export default ReturnsModal
