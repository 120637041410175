import { useState, useEffect, useContext } from "react"
import moment from "moment"
import { 
  TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
  Grid, Paper, Select, InputLabel, FormControl, MenuItem, Button,
  Box, Typography, Card, CardHeader, CardContent, Divider
} from "@mui/material"
import ResidenceModal from "components/residences/ResidenceModal"
import DeleteDialog from "components/common/DeleteDialog"
import { AuthContext } from "helpers/auth"
import { UsersContext } from "helpers/users"
import ResidenceFileModal from "components/residences/ResidenceFileModal"
import ResidenceMapChart from "components/residences/charts/ResidenceMapChart"
import ResidencePieChart from "components/residences/charts/ResidencePieChart"

const ResidencePage = () => {
  const [filters, setFilters] = useState({ user: "", year: "", country: "" })
  const [filterOptions, setFilterOptions] = useState({ user: [], year: [], country: [] })
  const [residencesList, setResidencesList] = useState([])
  const [daysCountPerCountry, setDaysCountPerCountry] = useState([])
  const [updated, setUpdated] = useState(0)
  const auth = useContext(AuthContext)
  const users = useContext(UsersContext)
  const toggleUpdate = () => setUpdated(!updated)
  const filterPropertyOnChange = (e) => setFilters({...filters, [e.target.name]: e.target.value})

  const deleteResidence = async (res) => {
    await fetch(`${process.env.REACT_APP_API_PROXY}/api/residences/${encodeURIComponent(res._id)}`, { 
      method: "DELETE", 
      credentials: "include",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + auth.user.token
      }
    })
    toggleUpdate()
  }

  useEffect(() => {
    if (auth.user) {
      const fetchResidenceData = async () => {
        const taskResponse = await fetch(`${process.env.REACT_APP_API_PROXY}/api/residences?` + new URLSearchParams({
          user: filters.user,
          year: filters.year,
          country: filters.country
        }), {
          method: "GET", 
          credentials: "include",
          headers: { 
            "Content-Type": "application/json",
            "Authorization": "Bearer " + auth.user.token
          }
        })
        const response = await taskResponse.json()
        setResidencesList(response.residences)
        setDaysCountPerCountry(response.daysCountPerCountry)
        setFilterOptions(response.filterOptions)
      }
  
      fetchResidenceData()
    }
  }, [auth, updated, filters])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box sx={{ alignItems: "center", display: "flex", justifyContent: "space-between", flexWrap: "wrap", m: -1 }}>
          <Typography sx={{ m: 1 }} variant="h4">Residences</Typography>
          <Box sx={{ m: 1 }}>
            <ResidenceModal actions={{ toggleUpdate }} />
          </Box>
        </Box>
      </Grid>

      {/* World Map */}
      <Grid item xs={8}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", height: "100%" }}>
          <ResidenceMapChart 
            setCurrentCountry={(country) => setFilters({...filters, country})} 
            daysCountPerCountry={daysCountPerCountry}
            height={450} 
          />
        </Paper>
      </Grid>

      {/* Task Status Pie Chart */}
      <Grid item xs={4}>
        <Card sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <CardHeader 
            title={
              `Days per country for ${filters.user ? users.findNameFromId(filters.user) : "all users"} ` +
              `in ${filters.year ? filters.year : "all years"}`
            }
          />
          <Divider />
          <CardContent sx={{ px: 4 }}>
            <ResidencePieChart 
              currentCountry={filters.country}
              daysCountPerCountry={daysCountPerCountry}
              setCurrentCountry={(country) => setFilters({...filters, country})} 
            />
          </CardContent>
        </Card>
      </Grid>

      {/* Filters */}
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", height: "100%" }}>
          <Grid container spacing={2}>
            {/* User filter */}
            <Grid item xs={3}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel shrink>User</InputLabel>
                <Select displayEmpty notched label="User" value={filters.user} name={"user"} onChange={filterPropertyOnChange}>
                  <MenuItem key={"All Users"} value="">All Users</MenuItem>
                    {filterOptions.user.map(user => (
                      <MenuItem key={user} value={user}>{users.findNameFromId(user)}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Year filter */}
            <Grid item xs={3}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel shrink>User</InputLabel>
                <Select displayEmpty notched label="Year" value={filters.year} name={"year"} onChange={filterPropertyOnChange}>
                  <MenuItem key={"All Years"} value="">All Years</MenuItem>
                    {filterOptions.year.map(year => (
                      <MenuItem key={year} value={year}>{year}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Country filter */}
            <Grid item xs={3}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel shrink>Country</InputLabel>
                <Select displayEmpty notched label="Country" value={filters.country} name={"country"} onChange={filterPropertyOnChange}>
                  <MenuItem key={"All Countries"} value="">All Countries</MenuItem>
                  { filterOptions.country.map(country => {
                    return (
                      <MenuItem key={country} value={country}>{country}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>

            {/* Clear all filters */}
            <Grid item xs={3}>
              <Button 
                variant="outlined" 
                sx={{ height: "100%", width: "100%" }}
                onClick={() => { setFilters({ user: "", year: "", country: "" }) }}
              >
                Clear filters
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      {/* Tasks */}
      <Grid item xs={12}>
        <TableContainer component={Paper} sx={{ height: "100%" }} >
          <Table sx={{border: "1px grey"}}>
            <colgroup>
              <col style={{width:"20%"}} />
              <col style={{width:"15%"}} />
              <col style={{width:"15%"}} />
              <col style={{width:"15%"}} />
              <col style={{width:"15%"}} />
              <col style={{width:"20%"}} />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Country</TableCell>
                <TableCell>Number of Days</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {residencesList.map(res => (
                <TableRow key={res._id}>
                  <TableCell>{users.findNameFromId(res.user)}</TableCell>
                  <TableCell>{res.country}</TableCell>
                  <TableCell>{Math.round(res.numDays)}</TableCell>
                  <TableCell>{moment(new Date(res.startDate)).format("YYYY-MM-DD")}</TableCell>
                  <TableCell>{moment(new Date(res.endDate)).format("YYYY-MM-DD")}</TableCell>
                  <TableCell align="center">
                    <ResidenceFileModal residence={res} />
                    <ResidenceModal residence={res} actions={{ toggleUpdate }} />
                    <DeleteDialog objectName={"residence"} deleteFunction={() => deleteResidence(res)} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default ResidencePage
