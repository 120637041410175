import { Fragment, useState } from "react"
import { 
  IconButton, Button, Dialog, DialogActions, 
  DialogContent, DialogContentText, DialogTitle, Link as MUILink
} from "@mui/material"
import { Delete as DeleteIcon } from "@mui/icons-material"

const DeleteDialog = ({ objectName, deleteFunction, linkOnly }) => {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => { setOpen(true) }
  const handleClose = () => { setOpen(false) }

  const deleteConfirm = () => {
    deleteFunction()
    handleClose()
  }

  return (
    <Fragment>
      {linkOnly
      ?
        <MUILink href="#" sx={{ pl: 1 }} onClick={handleClickOpen}>
          Delete
        </MUILink>
      :
        <IconButton variant="contained" onClick={handleClickOpen}>
          <DeleteIcon />
        </IconButton>
      }
      
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Confirm delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you would like to delete this { objectName || "item" }?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={deleteConfirm} autoFocus>Delete</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default DeleteDialog
