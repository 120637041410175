import { useState, useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom"
import socketIO from "socket.io-client"
import moment from "moment"
import { Box, List, ListItem, ListItemText, Popover, Typography, IconButton } from "@mui/material"
import { Close as CloseIcon } from "@mui/icons-material"
import { theme } from "theme"
import { AuthContext } from "helpers/auth"

const socket = socketIO.connect(process.env.REACT_APP_API_PROXY)

const Notifications = ({ anchorEl, onClose, open, setNotificationsCount }) => {
  const [notifications, setNotifications] = useState([])
  const navigate = useNavigate()
  const auth = useContext(AuthContext)

  const deleteNotification = async (notification) => {
    await fetch(`${process.env.REACT_APP_API_PROXY}/api/notifications/${encodeURIComponent(notification._id)}`, { 
      method: "DELETE", 
      credentials: "include",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + auth.user.token
      }
    })
    setNotifications((notifications) => notifications.filter((n) => n._id !== notification._id))
    setNotificationsCount((n) => n - 1)
  }

  useEffect(() => {
    if (auth.user) { 
      const fetchNotificationsData = async () => {
        const notifResponse = await fetch(`${process.env.REACT_APP_API_PROXY}/api/notifications`, { 
          method: "GET", 
          credentials: "include",
          headers: { 
            "Content-Type": "application/json",
            "Authorization": "Bearer " + auth.user.token
          }
        })
        const response = await notifResponse.json()
        setNotifications(response)
        setNotificationsCount(response.length)
      }

      fetchNotificationsData()

      socket.on(auth.user._id, (data) => {
        setNotifications(notifications => [...notifications, data])
        setNotificationsCount((n) => n + 1)
      })

      return () => { socket.off(auth.user._id) }
    }
  }, [auth.user, setNotificationsCount])

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: "400px", maxHeight: "500px" } }}
    >
      <Box sx={{ px: 2 }} >
        <Typography variant="overline">Notifications</Typography>
      </Box>
      <List 
        dense 
        sx={{
          "& > *": {
            borderTopColor: "divider",
            borderTopStyle: "solid",
            borderTopWidth: "1px"
          }
        }}
      >
        {notifications.length !== 0 
          ? 
            notifications.map((notification) => (
              <ListItem 
                key={notification._id}
                onClick={() => navigate(notification.path)}
                sx={{ cursor: "pointer", "&:hover": { backgroundColor: theme.palette.action.selected } }}
                secondaryAction={
                  <IconButton edge="end" onClick={() => deleteNotification(notification)}>
                    <CloseIcon />
                  </IconButton>
                }
              >
                <ListItemText
                  primary={notification.message}
                  secondary={moment(notification.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                />
              </ListItem>
            )) 
          : 
            <Box sx={{ py: 1.5, px: 2 }} >
              <Typography variant="text.secondary">No new notifications!</Typography>
            </Box>
        }
      </List>
    </Popover>
  )
}

export default Notifications
