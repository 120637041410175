import { useContext, useEffect, useState } from "react"
import { Grid, Box, Paper, Typography, Card, CardContent, CardActions } from "@mui/material"
import NoteModal from "components/tasks/notes/NoteModal"
import DeleteDialog from "components/common/DeleteDialog"
import { AuthContext } from "helpers/auth"
import { UsersContext } from "helpers/users"

const NotesComponent = ({ task }) => {
  const [notes, setNotes] = useState([])
  const [updated, setUpdated] = useState(0)
  const auth = useContext(AuthContext)
  const users = useContext(UsersContext)
  const toggleUpdate = () => setUpdated(!updated)

  const deleteNote = async (note) => {
    await fetch(`${process.env.REACT_APP_API_PROXY}/api/tasks/${encodeURIComponent(task._id)}/notes/${encodeURIComponent(note._id)}`, { 
      method: "DELETE", 
      credentials: "include",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + auth.user.token
      }
    })
    setNotes((notes) => notes.filter((n) => n._id !== note._id))
  }

  useEffect(() => {
    if (auth.user) {
      const fetchNoteData = async () => {
        const noteResponse = await fetch(`${process.env.REACT_APP_API_PROXY}/api/tasks/${encodeURIComponent(task._id)}/notes`, { 
          method: "GET", 
          credentials: "include",
          headers: { 
            "Content-Type": "application/json",
            "Authorization": "Bearer " + auth.user.token
          }
        })
        const response = await noteResponse.json()
        setNotes(response.notes)
      }
  
      fetchNoteData()
    }
  }, [auth.user, updated, task._id])

  return ( 
    <Paper elevation={6} sx={{ px: 2, pt: 1, pb: 2, display: "flex", flexDirection: "column", height: "100%" }}>
      {/* Notes header and add button */}
      <Grid container alignItems="center">
        <Grid item xs={9}>
          <Typography variant="subtitle2" component="div">Notes</Typography>
        </Grid>
        <Grid item xs={3}>
          <Box sx={{ display: "flex", justifyContent: "flex-end"}}>
            <NoteModal task={task} actions={{ toggleUpdate }}/>
          </Box>
        </Grid>
      </Grid>

      {/* Notes main content */}
      <Grid container spacing={1}>
        {notes ? notes.map((note) => (
          <Grid item xs={12} key={note._id}>
            <Card key={note._id} elevation={1}>
              {/* Note content */}
              <CardContent>
                {note.content}
              </CardContent>

              {/* Note action buttons */}
              <CardActions disableSpacing>
                <Grid container alignItems="center">
                  <Grid item xs={9}>
                    <Typography variant="caption" component="span" sx={{ pl: 1 }}>
                      Last updated by: {users.findNameFromId(note.updatedBy)}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sx={{ display: "flex", justifyContent: "flex-end"}}>
                    <NoteModal task={task} note={note} actions={{ toggleUpdate }}/>
                    <DeleteDialog objectName={"note"} deleteFunction={() => deleteNote(note)} />
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        )) : null}
      </Grid>
    </Paper>
  )
}
 
export default NotesComponent
