import { Fragment, useState, useContext } from "react"
import { 
  Button, Modal, Box, Divider, TextField, Grid, IconButton,
  Select, MenuItem, FormControl, InputLabel
} from "@mui/material"
import { Edit as EditIcon } from "@mui/icons-material"
import { ValidatorForm } from "react-material-ui-form-validator"
import { AuthContext } from "helpers/auth"
import COUNTRIES from "helpers/countries"

const EntityModal = ({ entity, actions }) => {
  const [name, setName] = useState(entity ? entity.name : "")
  const [country, setCountry] = useState(entity ? entity.country : COUNTRIES[0])
  const [open, setOpen] = useState(false)
  const auth = useContext(AuthContext)

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false) 
    actions.toggleUpdate()
  }

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  } 

  const handleSubmit = async () => {
    try {
      const url = entity 
        ? `${process.env.REACT_APP_API_PROXY}/api/entities/${encodeURIComponent(entity._id)}` 
        : `${process.env.REACT_APP_API_PROXY}/api/entities/`
      const method = entity ? "PUT" : "POST"
      
      await fetch(url, { 
        method: method, 
        credentials: "include",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": "Bearer " + auth.user.token
        },
        body: JSON.stringify({ name, country }) 
      })
      
      if (!entity) { setName(""); setCountry("") }
      handleClose()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Fragment>
      {entity ? 
        <IconButton variant="contained" onClick={handleOpen}>
          <EditIcon />
        </IconButton> 
      : 
        <Button color="primary" variant="contained" onClick={handleOpen}>
          Add Entity
        </Button>
      }
      <Modal open={open} onClose={handleClose}>
        <Box sx={boxStyle}>
          <ValidatorForm onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Header */}
              <Grid item xs={12}>
                {entity ? "Edit" : "Add"} legal entity
                <Divider sx={{ p: 1 }} />  
              </Grid>

              {/* Entity name */}
              <Grid item xs={9}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)} 
                />
              </Grid>

              {/* Entity country */}
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel>Country</InputLabel>
                  <Select 
                    variant="outlined"
                    label="Country" 
                    value={country} 
                    onChange={(e) => {setCountry(e.target.value)}}
                  >
                    { COUNTRIES.map(c => <MenuItem key={c} value={c}>{c}</MenuItem>) }
                  </Select>
                </FormControl>
              </Grid>

              {/* Submit */}
              <Grid item xs={12}>
                <Button variant="contained" type="submit">Submit</Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        </Box>
      </Modal>
    </Fragment>
  )
}

export default EntityModal
