import { useState, useEffect, useContext } from "react"
import ReactTooltip from "react-tooltip"
import { 
  TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
  Grid, Paper, Select, InputLabel, FormControl, MenuItem, Button,
  Card, CardHeader, CardContent, Divider, FormControlLabel, Switch
} from "@mui/material"
import TaskRow from "components/tasks/TaskRow"
import MapChart from "components/tasks/charts/MapChart"
import TaskPieChart from "components/tasks/charts/TaskPieChart"
import COUNTRIES from "helpers/countries"
import { AuthContext } from "helpers/auth"
import { UsersContext } from "helpers/users"
import generateXlsx from "helpers/excel"

const TaskPage = () => {
  const [content, setContent] = useState({})
  const [currentCountry, setCurrentCountry] = useState("")
  const [currentEntity, setCurrentEntity] = useState("")
  const [currentStatus, setCurrentStatus] = useState("")
  const [currentUser, setCurrentUser] = useState("")
  const [taskCountPerCountry, setTaskCountPerCountry] = useState([])
  const [taskList, setTaskList] = useState([])
  const [showArchived, setShowArchived] = useState(false)
  const auth = useContext(AuthContext)
  const users = useContext(UsersContext)

  useEffect(() => {
    if (auth.user) {
      const fetchTaskListData = async () => {
        const taskResponse = await fetch(`${process.env.REACT_APP_API_PROXY}/api/tasks?` + new URLSearchParams({
          isArchived: showArchived
        }), { 
          method: "GET", 
          credentials: "include",
          headers: { 
            "Content-Type": "application/json",
            "Authorization": "Bearer " + auth.user.token
          }
        })
        const response = await taskResponse.json()
        setTaskList(response.tasks)
        setTaskCountPerCountry(response.taskCountPerCountry)
      }
  
      fetchTaskListData()
    }
  }, [auth, showArchived])

  return (
    <Grid container spacing={3}>
      {/* World Map */}
      <Grid item xs={8}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", height: "100%" }}>
          <MapChart 
            setTooltipContent={setContent} 
            setCurrentCountry={setCurrentCountry} 
            taskCountPerCountry={taskCountPerCountry} 
            height={450} 
          />
          <ReactTooltip className="tooltip">
            { content && 
              <div>
                <div style={{ "paddingBottom": 5 }}>{content.countryName}</div>
                <div>Pending: {content.tasksPending}</div>
                <div>Done: {content.tasksDone}</div>
                <div>Overdue: {content.tasksOverdue}</div>
              </div>
            }
          </ReactTooltip>
        </Paper>
      </Grid>

      {/* Task Status Pie Chart */}
      <Grid item xs={4}>
        <Card sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <CardHeader title={`Task status for ${currentCountry ? currentCountry : "all countries"}`} />
          <Divider />
          <CardContent sx={{ px: 4 }}>
            <TaskPieChart 
              currentCountry={currentCountry} 
              taskCountPerCountry={taskCountPerCountry} 
              setCurrentStatus={setCurrentStatus} 
            />
          </CardContent>
        </Card>
      </Grid>

      {/* Filters */}
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", height: "100%" }}>
          <Grid container spacing={2}>
            {/* Country filter */}
            <Grid item xs={2}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel shrink>Country</InputLabel>
                <Select displayEmpty notched label="Country" value={currentCountry} onChange={(e) => {setCurrentCountry(e.target.value)}}>
                  <MenuItem key={"All Countries"} value="">All Countries</MenuItem>
                  { COUNTRIES.map(country => {
                    return (
                      <MenuItem key={country} value={country}>{country}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>

            {/* Legal entity filter */}
            <Grid item xs={2}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel shrink>Legal Entity</InputLabel>
                <Select displayEmpty notched label="Legal Entity" value={currentEntity} onChange={(e) => {setCurrentEntity(e.target.value)}}>
                  <MenuItem key={"All Entities"} value="">All Entities</MenuItem>
                    {users.entityList?.entities?.map(ent => (
                      <MenuItem key={ent._id} value={ent._id}>{ent.name}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Status filter */}
            <Grid item xs={2}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel shrink>Task Status</InputLabel>
                <Select displayEmpty notched label="Task Status" value={currentStatus} onChange={(e) => {setCurrentStatus(e.target.value)}}>
                  <MenuItem key={"All Statuses"} value="">All Statuses</MenuItem>
                    <MenuItem key={"Pending"} value={"Pending"}>Pending</MenuItem>
                    <MenuItem key={"Done"} value={"Done"}>Done</MenuItem>
                    <MenuItem key={"Tasks Overdue"} value={"Tasks Overdue"}>Tasks Overdue</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Assigned user filter */}
            <Grid item xs={2}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel shrink>Assigned User</InputLabel>
                <Select displayEmpty notched label="Assigned User" value={currentUser} onChange={(e) => {setCurrentUser(e.target.value)}}>
                  <MenuItem key={"All Users"} value="">All Users</MenuItem>
                    {users.usersList?.map(user => (
                      <MenuItem key={user._id} value={user._id}>{user.firstname} {user.lastname}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Clear all filters */}
            <Grid item xs={2}>
              <Button 
                variant="outlined" 
                sx={{ height: "100%", width: "100%" }}
                onClick={() => {
                  setCurrentCountry("")
                  setCurrentEntity("")
                  setCurrentStatus("")
                  setCurrentUser("")
                }}
              >
                Clear filters
              </Button>
            </Grid>

            {/* Export to XLSX */}
            <Grid item xs={2}>
              <Button 
                variant="outlined" 
                sx={{ height: "100%", width: "100%" }}
                onClick={() => { generateXlsx(taskList, users) }}
              >
                Export to XLSX
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      {/* Archived filter */}
      <Grid item xs={12}>
        <FormControlLabel sx={{ pl: 2 }}
          control={(
            <Switch checked={showArchived} onChange={(e) => setShowArchived(e.target.checked)}/>
          )}
          label="Show archived tasks"
        />
      </Grid>

      {/* Tasks */}
      <Grid item xs={12}>
        <TableContainer component={Paper} sx={{ height: "100%" }} >
          <Table sx={{border: "1px grey"}}>
            <colgroup>
              <col style={{width:"1%"}} />
              <col style={{width:"30%"}} />
              <col style={{width:"12%"}} />
              <col style={{width:"13%"}} />
              <col style={{width:"13%"}} />
              <col style={{width:"13%"}} />
              <col style={{width:"13%"}} />
              <col style={{width:"5%"}} />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Deadline Date</TableCell>
                <TableCell>Country</TableCell>
                <TableCell>Legal Entity</TableCell>
                <TableCell>Created by</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {taskList ? taskList.map((task) => {
                let includeTask = true
                if  ((currentCountry && task.obligation?.country !== currentCountry)
                  || (currentEntity  && task.entity?._id !== currentEntity)
                  || (currentStatus  && task.status !== currentStatus)
                  || (currentUser    && !task.assignedUsers.map((u) => u._id).includes(currentUser))) includeTask = false

                return includeTask ? <TaskRow key={task._id} task={task} /> : null
              }) : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default TaskPage
