import { useState, memo, useEffect } from "react"
import ReactTooltip from "react-tooltip"
import { ZoomableGroup, ComposableMap, Geographies, Geography } from "react-simple-maps"
import { scaleLinear } from "d3-scale"
import { theme } from "theme"

const ResidenceMapChart = ({ setCurrentCountry, daysCountPerCountry, height }) => {
  const [tooltipContent, setTooltipContent] = useState({})
  const [maxDaysCount, setMaxDaysCount] = useState(0)
  
  const onMouseEnter = (geo) => {
    setTooltipContent({
      countryName: geo.properties.name
    })
  }

  const onClick = (geo) => {
    setCurrentCountry(geo.properties.name)
  }

  const onMouseLeave = () => {
    setTooltipContent()
  }

  const linearColorScale = scaleLinear()
    .domain([0, maxDaysCount])
    .range(["#D6D6DA", theme.palette.success.main])

  const getCountryColor = (countryName) => {
    const countryCount = daysCountPerCountry.find(c => c.country === countryName)
    return countryCount ? linearColorScale(countryCount.count) : "#D6D6DA"
  }

  useEffect(() => {
    if (daysCountPerCountry.length !== 0) {
      setMaxDaysCount(Math.max(...daysCountPerCountry.map(country => country.count)))
    }
  }, [daysCountPerCountry, maxDaysCount])

  return (
    <div data-tip="">
      <ComposableMap height={height} style={{borderColor:"black", borderWidth: 1}}>
        <ZoomableGroup center={[14, 8]}>
          <Geographies geography="/features.json">
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onClick={() => onClick(geo)}
                  onMouseEnter={() => onMouseEnter(geo)}
                  onMouseLeave={() => onMouseLeave()}
                  style={{
                    default: {
                      fill: getCountryColor(geo.properties.name),
                      stroke: "#F6F6FA",
                      outline: "none"
                    },
                    hover: {
                      fill: "#F53",
                      outline: "none"
                    },
                    pressed: {
                      fill: "#E42",
                      outline: "none"
                    }
                  }}
                />
              ))
            }
          </Geographies>
          {/* <Marker coordinates={[-74.006, 40.7128]}>
            <circle r={1} fill="#F53" />
          </Marker> */}
        </ZoomableGroup>
      </ComposableMap>
      <ReactTooltip className="tooltip">
        { tooltipContent && 
          <div>
            <div>{tooltipContent.countryName}</div>
          </div>
        }
      </ReactTooltip>
    </div>
  )
}

export default memo(ResidenceMapChart)
