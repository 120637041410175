import { useContext } from "react"
import { useTheme } from "@mui/material/styles"
import { Box, OutlinedInput, InputLabel, MenuItem, FormControl, Select, Chip } from "@mui/material"
import { UsersContext } from "helpers/users"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const getStyles = (name, assignedUsers, theme) => {
  return {
    fontWeight:
      assignedUsers.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  }
}

const UserSelect = ({ assignedUsers, setAssignedUsers }) => {
  const users = useContext(UsersContext)
  const theme = useTheme()

  const handleChange = (event) => {
    // On autofill we get a stringified value
    const value = event.target.value
    setAssignedUsers(typeof value === "string" ? value.split(",") : value)
  }

  return (
    <FormControl sx={{ width: "100%" }}>
      <InputLabel>Assigned Users</InputLabel>
      <Select
        multiple
        value={assignedUsers}
        onChange={handleChange}
        input={<OutlinedInput label="Assigned Users" />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((id) => (
              <Chip key={id} label={users.findNameFromId(id)} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {users.usersList.map((user) => (
          <MenuItem
            key={user._id}
            value={user._id}
            style={getStyles(user._id, assignedUsers, theme)}
          >
            {users.findNameFromId(user._id)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default UserSelect
