import "chart.js/auto"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { Fragment, useEffect, useState } from "react"
import { Doughnut } from "react-chartjs-2"
import { theme } from "theme"

const TaskPieChart = ({ currentCountry, taskCountPerCountry, setCurrentStatus }) => {
  const [data, setData] = useState([])
  const datasetLabels = ["Pending", "Done", "Tasks Overdue"]

  const pieOptions = {
    plugins: {
      legend: {
        display: false,
        showTooltips: false
      },
      tooltip: {
        enabled: false
      },
      title: {
        display: false
      },
      datalabels: {
        formatter: (value, context) => {
          return value ? datasetLabels[context.dataIndex] + "\n" + value : ""
        },
        color: "white",
        textAlign: "center"
      }
    }, 
    onClick: (event, element) => {
      if (element.length > 0) {
        setCurrentStatus(datasetLabels[element[0].index])
      }
    }
  }

  const taskStatusData = {
    labels: datasetLabels,
    datasets: [
      { 
        label: "Task Status", 
        data: data,
        backgroundColor: [
          theme.palette.warning.main, // Pending - yellow
          theme.palette.success.main, // Done - green
          theme.palette.error.main,   // Tasks Overdue - red
        ]
      }
    ]
  }

  useEffect(() => {
    if (taskCountPerCountry.length !== 0 && currentCountry) {
      const counts = taskCountPerCountry.find(c => c._id === currentCountry)
      counts ? setData([counts["Pending"], counts["Done"], counts["Tasks Overdue"]]) : setData([0, 0, 0])
    } else if (taskCountPerCountry.length !== 0) {
      const countStatus = (status) => taskCountPerCountry.map(c => c[status]).reduce((total, count) => (total + count), 0)
      setData([countStatus("Pending"), countStatus("Done"), countStatus("Tasks Overdue")])
    } else {
      setData([0, 0, 0])
    }
  }, [currentCountry, taskCountPerCountry])

  return (
    <Fragment>
      <Doughnut 
        data={taskStatusData} 
        plugins={[ChartDataLabels]} 
        options={pieOptions}
      />
    </Fragment>
  )
}

export default TaskPieChart