import { useContext, useRef, useState, Fragment } from "react"
import { useNavigate } from "react-router-dom"
import { styled } from "@mui/material/styles"
import { Toolbar, AppBar, Avatar, Box, MenuItem, MenuList, Popover, Typography, Badge, IconButton, Tooltip } from "@mui/material"
import { Notifications as NotificationsIcon, Menu as MenuIcon, ChevronLeft as ChevronLeftIcon } from "@mui/icons-material"
import { AuthContext } from "helpers/auth"
import Notifications from "components/layout/Notifications"

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3]
}))

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))

const AccountPopover = ({ anchorEl, onClose, open }) => {
  const auth = useContext(AuthContext)
  const navigate = useNavigate()

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: "300px" } }}
    >
      <Box sx={{ py: 1.5, px: 2 }} >
        <Typography variant="overline">Account</Typography>
        <Typography color="text.secondary" variant="body1">{auth.user?.firstname} {auth.user?.lastname}</Typography>
        <Typography color="text.secondary" variant="body2">{auth.user?.email}</Typography>
      </Box>
      <MenuList
        disablePadding
        sx={{
          "& > *": {
            "&:first-of-type": {
              borderTopColor: "divider",
              borderTopStyle: "solid",
              borderTopWidth: "1px"
            },
            padding: "12px 16px"
          }
        }}
      >
        <MenuItem onClick={() => { auth.signout(() => navigate("/login")) }}>Sign out</MenuItem>
      </MenuList>
    </Popover>
  )
}

const Navbar = (props) => {
  const { onSidebarOpen, isSidebarOpen, sidebarWidth, ...other } = props
  const [openAccountPopover, setOpenAccountPopover] = useState(false)
  const [openNotificationsPopover, setOpenNotificationsPopover] = useState(false)
  const [notificationsCount, setNotificationsCount] = useState(0)
  const auth = useContext(AuthContext)
  const settingsRef = useRef(null)
  const notifsRef = useRef(null)

  return (
    <Fragment>
      <DashboardNavbarRoot
        sx={{
          left: isSidebarOpen ? sidebarWidth : 0,
          width: isSidebarOpen ? `calc(100% - ${sidebarWidth}px)` : '100%',
        }}
        {...other}
      >
        <Toolbar disableGutters sx={{ minHeight: 64, left: 0, px: 2 }}>
          <IconButton onClick={onSidebarOpen} sx={{ display: 'inline-flex' }}>
            {isSidebarOpen ? (
              <ChevronLeftIcon fontSize="medium" />
            ) : (
              <MenuIcon fontSize="medium" />
            )}
          </IconButton>

          <Box sx={{ flexGrow: 1 }} />

          {/* Notifications popover */}
          <Tooltip title="Notifications">
            <IconButton 
              onClick={() => setOpenNotificationsPopover(true)}
              sx={{ cursor: "pointer", mr: 2 }}
            >
              <StyledBadge 
                ref={notifsRef} 
                badgeContent={notificationsCount} 
                color="primary"
              >
                <NotificationsIcon color="action" />
              </StyledBadge>
            </IconButton>
          </Tooltip>
          
          {/* Account popover */}
          <Avatar
            onClick={() => setOpenAccountPopover(true)}
            ref={settingsRef}
            sx={{ cursor: "pointer", height: 40, width: 40, ml: 1 }}
          >
            {auth.user?.firstname[0]}{auth.user?.lastname[0]}
          </Avatar>
        </Toolbar>
      </DashboardNavbarRoot>
      <Notifications 
        anchorEl={notifsRef.current}
        open={openNotificationsPopover}
        onClose={() => setOpenNotificationsPopover(false)}
        setNotificationsCount={setNotificationsCount}
      />
      <AccountPopover 
        anchorEl={settingsRef.current}
        open={openAccountPopover}
        onClose={() => setOpenAccountPopover(false)}
      />
    </Fragment>
  )
}

export default Navbar
