import { Mention, MentionsInput } from "react-mentions"

const defaultStyle = {
  control: {
    backgroundColor: "#fff",
    fontSize: 14,
    fontWeight: "normal"
  },

  "&multiLine": {
    control: {
      minHeight: 63
    },
    highlighter: {
      padding: 9,
      border: "1px solid transparent"
    },
    input: {
      padding: 9,
      border: "1px solid silver"
    }
  },

  "&singleLine": {
    display: "inline-block",
    width: 180,

    highlighter: {
      padding: 1,
      border: "2px inset transparent"
    },
    input: {
      padding: 1,
      border: "2px inset"
    }
  },

  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 14
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "#cee4e5"
      }
    }
  },

  input: {
    overflow: "auto",
    height: 70
  },

  highlighter: {
    boxSizing: "border-box",
    overflow: "hidden",
    height: 70
  }
}

const defaultMentionStyle = {
  backgroundColor: "#cee4e5"
}

const ScrollableMention = ({ data, value, setValue }) => {
  return (
    <MentionsInput
      value={value}
      onChange={(ev, newValue) => setValue(newValue)}
      style={defaultStyle}
      placeholder={"Mention people using '@'"}
    >
      <Mention
        markup="@(__id__)[__display__]"
        trigger="@"
        data={data}
        renderSuggestion={(suggestion, search, highlightedDisplay) => (
          <div className="user">{highlightedDisplay}</div>
        )}
        onAdd={() => (...args) => console.log("added a new mention", ...args)}
        style={defaultMentionStyle}
        forceSuggestionsAboveCursor={true}
      />
    </MentionsInput>
  )
}

export default ScrollableMention
