import { Fragment, useState, useContext } from "react"
import moment from "moment"
import { Button, Modal, Box, Divider, TextField, Grid, MenuItem, IconButton } from "@mui/material"
import { Edit as EditIcon, AddCircle as AddCircleIcon } from "@mui/icons-material"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator"
import UserSelect from "components/common/UserSelect"
import { AuthContext } from "helpers/auth"
import { UsersContext } from "helpers/users"
import { SnackbarContext } from "helpers/snackbar"
import { PHASES, PERIODS, TYPES, STATUSES } from "helpers/constants"

const TaskModal = ({ obligation, task, actions }) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    if (task) { 
      // Editing task in task page
      actions.toggleUpdate() 
    }
  }

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  }

  const [currentTask, setCurrentTask] = useState(
    task ? {
      ...task,
      entity: task.entity?._id,
      assignedUsers: task.assignedUsers.map(user => user._id)
    } 
    : {
      name: obligation.name,
      obligation: obligation._id,
      phase: PHASES[0],
      description: "",
      year: (new Date().getFullYear()).toString(),
      period: PERIODS[0],
      deadlineDate: moment(new Date()).format("YYYY-MM-DD"),
      taskType: TYPES[0],
      entity: "",
      status: STATUSES[0],
      assignedUsers: []
  })

  const taskPropertyOnChange = (e) => setCurrentTask({...currentTask, [e.target.name]: e.target.value})
  const auth = useContext(AuthContext)
  const users = useContext(UsersContext)
  const snackbar = useContext(SnackbarContext)

  const handleSubmit = async () => {
    try {
      const url = task ? `${process.env.REACT_APP_API_PROXY}/api/tasks/${encodeURIComponent(task._id)}` : `${process.env.REACT_APP_API_PROXY}/api/tasks`
      const method = task ? "PUT" : "POST"
      await fetch(url, { 
        method: method, 
        credentials: "include",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": "Bearer " + auth.user.token
        },
        body: JSON.stringify(currentTask) 
      })
      snackbar.setMessage({ message: `Task successfully ${task ? "updated" : "created"}!`, severity: "success" })
      handleClose()
    } catch (error) {
      console.log(error)
      snackbar.setMessage({ message: error.message, severity: "error" })
    }
  }

  return (
    <Fragment>
      <IconButton variant="contained" onClick={handleOpen}>
        {task ? <EditIcon /> : <AddCircleIcon />}
      </IconButton>
      <Modal open={open} onClose={handleClose}>
        <Box sx={boxStyle}>
          <ValidatorForm onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Header */}
              <Grid item xs={12}>
                {obligation.name}
                <Divider sx={{ p: 1 }} />  
              </Grid>

              {/* Task name */}
              <Grid item xs={9}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Task Name"
                  value={currentTask.name}
                  name={"name"}
                  onChange={taskPropertyOnChange}
                />
              </Grid>

              {/* Task phase */}
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  select
                  variant="outlined"
                  label="Phase"
                  value={currentTask.phase}
                  name={"phase"}
                  onChange={taskPropertyOnChange}
                >
                  {PHASES.map(phase => (
                    <MenuItem key={phase} value={phase}>{phase}</MenuItem>
                  ))}
                </TextField>
              </Grid>

              {/* Description */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Description"
                  value={currentTask.description}
                  name={"description"}
                  onChange={taskPropertyOnChange}
                />
              </Grid>

              {/* Year */}
              <Grid item xs={3}>
                <TextValidator
                  fullWidth
                  variant="outlined"
                  label="Year"
                  value={currentTask.year}
                  name={"year"}
                  onChange={taskPropertyOnChange}
                  inputProps={{ maxLength: 4 }}
                  validators={["matchRegexp:^20[0-9]{2}$"]}
                  errorMessages={["Value must be in the format 20XX"]}
                />
              </Grid>

              {/* Period */}
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  select
                  variant="outlined"
                  label="Period"
                  value={currentTask.period}
                  name={"period"}
                  onChange={taskPropertyOnChange}
                >
                  {PERIODS.map(period => (
                    <MenuItem key={period} value={period}>{period}</MenuItem>
                  ))}
                </TextField>
              </Grid>

              {/* Deadline date */}
              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Deadline Date"
                    value={currentTask.deadlineDate}
                    name={"deadlineDate"}
                    onChange={taskPropertyOnChange}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </LocalizationProvider>
              </Grid>

              {/* Type */}
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  select
                  variant="outlined"
                  label="Type"
                  value={currentTask.taskType}
                  name={"taskType"}
                  onChange={taskPropertyOnChange}
                >
                  {TYPES.map(type => (
                    <MenuItem key={type} value={type}>{type}</MenuItem>
                  ))}
                </TextField>
              </Grid>

              {/* Legal Entity */}
              <Grid item xs={3}>
                <SelectValidator
                  fullWidth
                  select
                  variant="outlined"
                  label="Legal Entity"
                  value={currentTask.entity}
                  name={"entity"}
                  onChange={taskPropertyOnChange}
                  validators={["required"]}
                  errorMessages={["This field is required"]}
                >
                  {users.entityList?.entities?.map(ent => (
                    <MenuItem key={ent._id} value={ent._id}>{ent.name}</MenuItem>
                  ))}
                </SelectValidator>
              </Grid>

              {/* Status */}
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  select
                  variant="outlined"
                  label="Status"
                  value={currentTask.status}
                  name={"status"}
                  onChange={taskPropertyOnChange}
                >
                  {STATUSES.map(status => (
                    <MenuItem key={status} value={status}>{status}</MenuItem>
                  ))}
                </TextField>
              </Grid>

              {/* Assigned users */}
              <Grid item xs={12}>
                <UserSelect 
                  assignedUsers={currentTask.assignedUsers} 
                  setAssignedUsers={(value) => setCurrentTask({...currentTask, assignedUsers: value})} />
              </Grid>

              {/* Submit */}
              <Grid item xs={12}>
                <Button variant="contained" type="submit">Submit</Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        </Box>
      </Modal>
    </Fragment>
  )
}

export default TaskModal
