import { useState, useEffect, useContext, Fragment } from "react"
import { 
  Grid, Typography, Box, Card, CardHeader, 
  Divider, CardContent, Button, TextField, MenuItem,
  FormControlLabel, Checkbox
} from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { AuthContext } from "helpers/auth"
import { SnackbarContext } from "helpers/snackbar"
import UserModal from "components/settings/UserModal"
import DeleteDialog from "components/common/DeleteDialog"

const SettingsPage = () => {
  const auth = useContext(AuthContext)
  const snackbar = useContext(SnackbarContext)
  const [usersList, setUsersList] = useState([])
  const [updated, setUpdated] = useState(0)
  const [userSettings, setUserSettings] = useState({})
  const toggleUpdate = () => setUpdated(!updated)

  const usersColumns = [
    { 
      field: "action",
      headerName: "Actions",
      sortable: false,
      minWidth: 100,
      renderCell: (params) => (
        <Fragment>
          <UserModal user={params.row} actions={{ toggleUpdate }} />
          <DeleteDialog objectName={"user"} deleteFunction={() => deleteUser(params.row)} />
        </Fragment>
      )
    },
    { 
      field: "name", 
      headerName: "Name", 
      minWidth: 300, 
      renderCell: (params) => {
        return `${params.row.firstname} ${params.row.lastname}${auth.user.email === params.row.email ? " (You)" : ""}`
      }
    },
    { field: "email", headerName: "Email", minWidth: 400 },
    { field: "isAdmin", headerName: "Admin", minWidth: 200 }
  ]

  const deleteUser = async (user) => {
    await fetch(`${process.env.REACT_APP_API_PROXY}/api/users/${encodeURIComponent(user._id)}`, { 
      method: "DELETE", 
      credentials: "include",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + auth.user.token
      }
    })
    setUsersList((usersList) => usersList.filter((u) => u._id !== user._id))
  }

  const saveSettings = async () => {
    try {
      const userResponse = await fetch(`${process.env.REACT_APP_API_PROXY}/api/users/my-settings`, { 
        method: "PUT", 
        credentials: "include",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": "Bearer " + auth.user.token
        },
        body: JSON.stringify(userSettings) 
      })

      if (userResponse.status === 200) {
        const response = await userResponse.json()
        auth.updateUserSettings(response.userSettings)
        snackbar.setMessage({ message: "Settings successfully updated!", severity: "success" })
      }
    } catch (error) {
      console.log(error)
      snackbar.setMessage({ message: error.message, severity: "error" })
    }
  }

  useEffect(() => {
    if (auth.user) {
      const fetchUserData = async () => {
        const userListResponse = await fetch(`${process.env.REACT_APP_API_PROXY}/api/users`, { 
          method: "GET", 
          credentials: "include",
          headers: { 
            "Content-Type": "application/json",
            "Authorization": "Bearer " + auth.user.token
          }
        })
        const response = await userListResponse.json()
        setUsersList(response)
      }
      
      fetchUserData()
      setUserSettings(auth.user.userSettings)
    }
  }, [updated, auth])

  return (
    <Grid container spacing={3}>
      {/* Header */}
      <Grid item xs={12}>
        <Box sx={{ alignItems: "center", display: "flex", justifyContent: "space-between", flexWrap: "wrap", m: -1 }}>
          <Typography sx={{ m: 1 }} variant="h4">Settings</Typography>
        </Box>
      </Grid>

      {/* Account Settings */}
      <Grid item xs={12}>
        <Card>
          <CardHeader subheader="Manage settings for your own account" title="User Preferences" />
          <Divider />
          
          {/* Main settings part */}
          <CardContent>
            <Grid container spacing={12} wrap="wrap">
              {/* First page */}
              <Grid item sx={{ display: "flex", flexDirection: "column" }} xs={6}>
                <Typography color="textPrimary" gutterBottom variant="h6" sx={{ pb: 1 }}>
                  First page after logging in
                </Typography>
                <TextField
                  fullWidth
                  select
                  variant="outlined"
                  value={userSettings?.firstPage || "/filings"}
                  onChange={(e) => setUserSettings({...userSettings, firstPage: e.target.value})} 
                >
                  <MenuItem key={"/filings"} value={"/filings"}>Filings</MenuItem>
                  <MenuItem key={"/returns"} value={"/returns"}>VAT Analysis</MenuItem>
                  <MenuItem key={"/tasks"} value={"/tasks"}>Tasks</MenuItem>
                  <MenuItem key={"/obligations"} value={"/obligations"}>Obligations</MenuItem>
                  <MenuItem key={"/projects"} value={"/projects"}>Projects</MenuItem>
                  <MenuItem key={"/customers"} value={"/customers"}>Directory</MenuItem>
                </TextField>
              </Grid>

              {/* Notifications */}
              <Grid item sx={{ display: "flex", flexDirection: "column" }} xs={6}>
                <Typography color="textPrimary" gutterBottom variant="h6" sx={{ pb: 1 }}>
                  Notifications
                </Typography>
                <FormControlLabel
                  control={(
                    <Checkbox 
                      color="primary" 
                      checked={userSettings?.notifyOnMention || false}
                      onChange={(e) => setUserSettings({...userSettings, notifyOnMention: e.target.checked})} 
                    />
                  )}
                  label="Send me an email when I am mentioned in a message"
                />
              </Grid>
            </Grid>
          </CardContent>
          
          <Divider />
          <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
            <Button color="primary" variant="contained" onClick={saveSettings}>Save</Button>
          </Box>
        </Card>
      </Grid>

      {/* Users */}
      <Grid item xs={12}>
        <Box sx={{ alignItems: "center", display: "flex", justifyContent: "space-between", flexWrap: "wrap", m: -1 }}>
          <Typography sx={{ m: 1 }} variant="h5">Users</Typography>
          <Box sx={{ m: 1 }}>
            <UserModal actions={{ toggleUpdate }} />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ height: 550 }}>
        <DataGrid
          rows={usersList}
          columns={usersColumns}
          getRowId={(row) => row._id}
          disableSelectionOnClick
        />
      </Grid>
    </Grid>
  )
}

export default SettingsPage
