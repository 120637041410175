import { Fragment, useState, useContext } from "react"
import moment from "moment"
import { Button, Modal, Box, Divider, TextField, Grid, IconButton } from "@mui/material"
import { Edit as EditIcon } from "@mui/icons-material"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { ValidatorForm } from "react-material-ui-form-validator"
import { AuthContext } from "helpers/auth"
import PropertyMap from "components/properties/PropertyMap"

const PropertyModal = ({ property, actions }) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    if (!property) { 
      setName("")
      setAddress("")
      setPropertyType("")
      setCoords({ lat: 0, lng: 0 })
      setMarketValue(0)
      setBookValue(0)
      setListingStatus("")
      setArea(0)
      setPricePerArea(0)
      setAcquisitionDate(moment(new Date()).format("YYYY-MM-DD"))
    }
  }

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    maxHeight: "1000px",
    overflow: "scroll"
  }

  const [name, setName] = useState(property ? property.name : "")
  const [address, setAddress] = useState(property ? property.address : "")
  const [propertyType, setPropertyType] = useState(property ? property.propertyType : "")
  const [coords, setCoords] =  useState(property ? property.coords : { lat: 0, lng: 0 })
  const [marketValue, setMarketValue] = useState(property ? property.marketValue : 0)
  const [bookValue, setBookValue] = useState(property ? property.bookValue : 0)
  const [listingStatus, setListingStatus] = useState(property ? property.listingStatus : "")
  const [area, setArea] = useState(property ? property.area : 0)
  const [pricePerArea, setPricePerArea] = useState(property ? property.pricePerArea : 0)
  const [acquisitionDate, setAcquisitionDate] = useState(property ? property.acquisitionDate : moment(new Date()).format("YYYY-MM-DD"))
  const auth = useContext(AuthContext)

  const handleSubmit = async () => {
    try {
      const url = property ? `${process.env.REACT_APP_API_PROXY}/api/properties/${encodeURIComponent(property._id)}` : `${process.env.REACT_APP_API_PROXY}/api/properties`
      const method = property ? "PUT" : "POST"
      
      await fetch(url, { 
        method: method, 
        credentials: "include",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": "Bearer " + auth.user.token
        },
        body: JSON.stringify({ 
          name, address, propertyType, coords, marketValue, 
          bookValue, listingStatus, area, pricePerArea, acquisitionDate 
        }) 
      })
      handleClose()
      actions.toggleUpdate()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Fragment>
      {property ? 
        <IconButton variant="contained" onClick={handleOpen}>
          <EditIcon />
        </IconButton> 
      : 
        <Button color="primary" variant="contained" onClick={handleOpen}>
          Add Property
        </Button>
      }
      <Modal open={open} onClose={handleClose}>
        <Box sx={boxStyle}>
          <ValidatorForm onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Header */}
              <Grid item xs={12}>
                {property ? "Edit" : "Add"} property
                <Divider sx={{ p: 1 }} />  
              </Grid>

              {/* Map */}
              <Grid item xs={12}>
                <PropertyMap 
                  formValues={{ name, address, propertyType, coords }}
                  formSetters={{ setName, setAddress, setPropertyType, setCoords }}
                />
              </Grid>

              {/* Property name */}
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)} 
                />
              </Grid>

              {/* Property address */}
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)} 
                />
              </Grid>

              {/* Property type */}
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Property Type"
                  value={propertyType}
                  onChange={(e) => setPropertyType(e.target.value)} 
                />
              </Grid>

              {/* Property coords */}
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  disabled
                  variant="outlined"
                  label="Coordinates"
                  value={`${coords.lat}, ${coords.lng}`}
                />
              </Grid>

              {/* Market value */}
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Market Value"
                  value={marketValue}
                  onChange={(e) => setMarketValue(e.target.value)} 
                />
              </Grid>

              {/* Book value value */}
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Book Value"
                  value={bookValue}
                  onChange={(e) => setBookValue(e.target.value)} 
                />
              </Grid>

              {/* Listing status */}
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Listing Status"
                  value={listingStatus}
                  onChange={(e) => setListingStatus(e.target.value)} 
                />
              </Grid>

              {/* Area */}
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Area"
                  value={area}
                  onChange={(e) => setArea(e.target.value)} 
                />
              </Grid>

              {/* Price per area */}
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Price per Area"
                  value={pricePerArea}
                  onChange={(e) => setPricePerArea(e.target.value)} 
                />
              </Grid>

              {/* Acquisition date */}
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Acquisition Date"
                    value={acquisitionDate}
                    onChange={(acquisitionDate) => {setAcquisitionDate(acquisitionDate)}}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </LocalizationProvider>
              </Grid>

              {/* Submit */}
              <Grid item xs={12}>
                <Button variant="contained" type="submit">Submit</Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        </Box>
      </Modal>
    </Fragment>
  )
}

export default PropertyModal
