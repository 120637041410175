import { Fragment, useState, useContext } from "react"
import { Button, Modal, Box, Divider, TextField, Grid, IconButton, MenuItem } from "@mui/material"
import { Edit as EditIcon } from "@mui/icons-material"
import { TextValidator, ValidatorForm, SelectValidator } from "react-material-ui-form-validator"
import { AuthContext } from "helpers/auth"
import COUNTRIES from "helpers/countries"

const CustomerModal = ({ customer, actions }) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    actions.toggleUpdate()
    if (!customer) { 
      setFirstname("")
      setLastname("")
      setTitle("")
      setCompanyName("")
      setCompanyAddress("")
      setPhone("")
      setEmail("")
      setWebsite("")
      setCountry("")
    }
  }

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  }

  const [firstname, setFirstname] = useState(customer ? customer.firstname : "")
  const [lastname, setLastname] = useState(customer ? customer.lastname : "")
  const [title, setTitle] = useState(customer ? customer.title : "")
  const [companyName, setCompanyName] = useState(customer ? customer.companyName : "")
  const [companyAddress, setCompanyAddress] = useState(customer ? customer.companyAddress : "")
  const [phone, setPhone] = useState(customer ? customer.phone : "")
  const [email, setEmail] = useState(customer ? customer.email : "")
  const [website, setWebsite] = useState(customer ? customer.website : "")
  const [country, setCountry] = useState(customer ? customer.country : "")
  
  const auth = useContext(AuthContext)

  const handleSubmit = async () => {
    try {
      const url = customer ? `${process.env.REACT_APP_API_PROXY}/api/customers/${encodeURIComponent(customer._id)}` : `${process.env.REACT_APP_API_PROXY}/api/customers`
      const method = customer ? "PUT" : "POST"
      await fetch(url, { 
        method: method, 
        credentials: "include",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": "Bearer " + auth.user.token
        },
        body: JSON.stringify({
          firstname,
          lastname,
          title,
          companyName,
          companyAddress,
          phone,
          email,
          website,
          country
        }) 
      })
      handleClose()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Fragment>
      {customer ? 
        <IconButton variant="contained" onClick={handleOpen}>
          <EditIcon />
        </IconButton> 
      : 
        <Button color="primary" variant="contained" onClick={handleOpen}>
          Add Contact
        </Button>
      }
      <Modal open={open} onClose={handleClose}>
        <Box sx={boxStyle}>
          <ValidatorForm onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Header */}
              <Grid item xs={12}>
              {customer ? "Edit" : "New"} contact
                <Divider sx={{ p: 1 }} />  
              </Grid>
            
              {/* First name */}
              <Grid item xs={4}>
                <TextValidator
                  fullWidth
                  variant="outlined"
                  label="First Name"
                  value={firstname}
                  validators={["required"]}
                  errorMessages={["This field is required."]}
                  onChange={(e) => setFirstname(e.target.value)} 
                />
              </Grid>

              {/* Last name */}
              <Grid item xs={4}>
                <TextValidator
                  fullWidth
                  variant="outlined"
                  label="Last Name"
                  value={lastname}
                  validators={["required"]}
                  errorMessages={["This field is required."]}
                  onChange={(e) => setLastname(e.target.value)} 
                />
              </Grid>

              {/* Country */}
              <Grid item xs={4}>
                <SelectValidator
                  fullWidth
                  label="Country"
                  name="country"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  validators={["required"]}
                  errorMessages={["Country is required"]}
                >
                  {COUNTRIES.map((country) => (
                    <MenuItem key={country} value={country}>
                      {country}
                    </MenuItem>
                  ))}
                </SelectValidator>
              </Grid>

              {/* Title */}
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)} 
                />
              </Grid>

              {/* Company name */}
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Company Name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)} 
                />
              </Grid>

              {/* Company address */}
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Company Address"
                  value={companyAddress}
                  onChange={(e) => setCompanyAddress(e.target.value)} 
                />
              </Grid>

              {/* Phone */}
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)} 
                />
              </Grid>

              {/* Email */}
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)} 
                />
              </Grid>

              {/* Website */}
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Website"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)} 
                />
              </Grid>

              {/* Submit */}
              <Grid item xs={12}>
                <Button variant="contained" type="submit">Submit</Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        </Box>
      </Modal>
    </Fragment>
  )
}

export default CustomerModal
