import { Fragment } from "react"
import { Link } from "react-router-dom"
import moment from "moment"
import { TableRow, TableCell, IconButton } from "@mui/material"
import { KeyboardArrowRight } from "@mui/icons-material"
import StatusCell from "components/tasks/StatusCell"

const ProjectRow = ({ task }) => {
  return (
    <Fragment>
      {/* Main cell content */}
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell />
        <TableCell>{task.name}</TableCell>
        <TableCell><StatusCell status={task.status} /></TableCell>
        <TableCell>{moment(task.deadlineDate).format("YYYY-MM-DD")}</TableCell>        
        <TableCell>{task.entity?.country}</TableCell>
        <TableCell>{task.entity?.name}</TableCell>
        <TableCell>{task.createdBy?.firstname} {task.createdBy?.lastname}</TableCell>
        <TableCell>
          <IconButton size="small">
            <Link to={task._id}><KeyboardArrowRight/></Link>
          </IconButton>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default ProjectRow
